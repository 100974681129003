import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  z-index: 3;
`;

const HiddenInput = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

export { HiddenInput, Wrapper };
