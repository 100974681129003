import React, { useMemo, useCallback, memo } from "react";
import nanoid from "nanoid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSetState } from "@hooks";
import { NinePlus, Amenity, DashboardCustomize, Star, Typology } from "@icons";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import ContentPanel from "@components/ContentPanel/ContentPanel";
import ExpansionPanel from "@components/ExpansionPanel/ExpansionPanel";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

import {
  Label,
  Button,
  Divider,
  Container,
  CardWrapper,
  DeleteButton,
  FilterWrapper,
  FilterPosition,
  AddGroupButton,
  AddGroupWrapper,
  FilterGroupTypes,
  FilterGroupWrapper,
  FilterGroupSelector,
} from "./Components";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const FILTER_GROUPS = [
  {
    type: "amenities",
    icon: Amenity,
    label: "Amenity",
  },
  {
    type: "typologies",
    icon: Typology,
    label: "Typology",
  },
  {
    type: "ratings",
    icon: NinePlus,
    label: "TY Rating",
  },
  {
    type: "stars",
    icon: Star,
    label: "Stars",
  },
  {
    type: "custom",
    icon: DashboardCustomize,
    label: "Custom",
  },
];

const FilterContent = ({
  type,
  data,
  updateData = () => {},
  filterIndex,
  parentIndex,
}) => {
  let fields = [];
  switch (type) {
    case "stars": {
      fields.push({
        name: "value",
        type: "number",
        min: 0,
        max: 5,
        label: "Stars (0-5)",
        required: true,
        id: `inner-filter-${parentIndex}-${type}-${filterIndex}`,
        information: "Return hotels with stars equal to this value",
        style: {
          wrapper: {
            marginBottom: "0",
          },
        },
      });
      break;
    }
    case "ratings": {
      fields.push({
        name: "value",
        type: "number",
        min: 50,
        max: 100,
        step: 10,
        required: true,
        label: "TrustYou score (50-100)",
        id: `inner-filter-${parentIndex}-${type}-${filterIndex}`,
        information:
          "Return hotels with score equal to or higher than this value",
        style: {
          wrapper: {
            marginBottom: "0",
          },
        },
      });
      break;
    }
    case "amenities": {
      fields.push({
        name: "value",
        type: "relation",
        collection: "amenities",
        label: "Amenity",
        required: true,
        id: `inner-filter-${parentIndex}-${type}-${filterIndex}`,
        information: "Return hotels that have this amenity",
        style: {
          wrapper: {
            marginBottom: "0",
          },
        },
      });
      break;
    }
    case "typologies": {
      fields.push({
        name: "value",
        type: "relation",
        collection: "typologies",
        label: "Accommodation type",
        required: true,
        id: `inner-filter-${parentIndex}-${type}-${filterIndex}`,
        information: "Return hotels with this typology",
        style: {
          wrapper: {
            marginBottom: "0",
          },
        },
      });
      break;
    }
    default: {
      fields.push({
        name: "value",
        type: "relation",
        collection: "tags",
        required: true,
        id: `inner-filter-${parentIndex}-${type}-${filterIndex}`,
        information: "Return hotels that have this tag",
        style: {
          wrapper: {
            marginBottom: "0",
          },
        },
      });
      break;
    }
  }

  return <FormBuilder fields={fields} data={data} updateData={updateData} />;
};

const InnerFilters = memo(
  ({
    filters = [],
    filterType = "custom",
    parentIndex,
    onDragEndInnerFilters,
    handleElementSelection,
    updateInnerFilter,
    removeInnerFilter,
    addInnerFilter,
    selectedId,
    selectedType,
  }) => {
    return (
      <DragDropContext
        onDragEnd={(result) => onDragEndInnerFilters(result, parentIndex)}
      >
        <Droppable droppableId={`droppable-filters-${parentIndex}`}>
          {(provided) => (
            <Container {...provided.droppableProps} ref={provided.innerRef}>
              <div>
                {filters.length > 0
                  ? filters.map((filter, index) => {
                      const uuid = filter.draggableId;

                      return (
                        <Draggable
                          key={uuid}
                          draggableId={uuid}
                          index={index}
                          isDragDisabled={
                            selectedType !== "filter" && selectedId !== uuid
                          }
                        >
                          {(provided) => (
                            <CardWrapper
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <FilterWrapper
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleElementSelection("filter", uuid);
                                }}
                                isSelected={uuid === selectedId}
                              >
                                <FilterPosition>{index + 1}</FilterPosition>
                                <FilterContent
                                  type={filterType}
                                  data={filter}
                                  filterIndex={index}
                                  parentIndex={parentIndex}
                                  updateData={(_, value) =>
                                    updateInnerFilter(parentIndex, index, value)
                                  }
                                />
                              </FilterWrapper>
                              <DeleteButton
                                onClick={() =>
                                  removeInnerFilter(parentIndex, index)
                                }
                                type="button"
                                isSelected={uuid === selectedId}
                              >
                                -
                              </DeleteButton>
                            </CardWrapper>
                          )}
                        </Draggable>
                      );
                    })
                  : null}
              </div>
              {provided.placeholder}
              <Button onClick={() => addInnerFilter(parentIndex)} type="button">
                Add filter
              </Button>
            </Container>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

const FilterPage = ({
  data = {},
  updateData = () => {},
  setState: parentSetState = () => {},
}) => {
  const { groups = [] } = data || {};

  const [state, setState] = useSetState({
    selectedId: null,
    selectedType: "group",
  });

  const { selectedId, selectedType } = state;

  const onDragEndFilterGroup = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }
      const items = reorder(
        groups,
        result.source.index,
        result.destination.index
      );

      updateData("groups", items);
    },
    [updateData, groups]
  );

  const addFilterGroup = useCallback(
    (groupType) => {
      const newFilterGroups = [
        ...(groups || []),
        {
          draggableId: nanoid(),
          filterType: groupType,
          filters: [],
          groupInternalName: "",
        },
      ];
      updateData("groups", newFilterGroups);
    },
    [updateData, groups]
  );

  const removeFilterGroup = useCallback(
    (index) => {
      let newFilterGroups = [...(groups || [])];
      newFilterGroups.splice(index, 1);
      updateData("groups", newFilterGroups);
    },
    [updateData, groups]
  );

  const updateFilterGroup = useCallback(
    (type, value, index) => {
      const newFilterGroups = [...(groups || [])];
      const groupFilterToUpdate = newFilterGroups[index] || {};
      groupFilterToUpdate[type] = value;
      newFilterGroups[index] = groupFilterToUpdate;
      updateData("groups", newFilterGroups);
    },
    [updateData, groups]
  );

  const onDragEndInnerFilters = useCallback(
    (result, index) => {
      if (!result.destination) {
        return;
      }

      let newFilterGroups = [...(groups || [])];

      const filters = newFilterGroups[index]?.filters || [];

      const items = reorder(
        filters,
        result.source.index,
        result.destination.index
      );

      newFilterGroups[index].filters = items;

      updateData("groups", newFilterGroups);
    },
    [updateData, groups]
  );

  const addInnerFilter = useCallback(
    (index) => {
      const newFilterGroups = [...(groups || [])];
      let filterGroup = newFilterGroups[index] || {};
      filterGroup.filters = [
        ...(filterGroup.filters || []),
        { draggableId: nanoid(), value: null },
      ];
      newFilterGroups[index] = filterGroup;
      updateData("groups", newFilterGroups);
    },
    [groups, updateData]
  );

  const removeInnerFilter = useCallback(
    (parentIndex, filterIndex) => {
      let newFilterGroups = [...(groups || [])];
      newFilterGroups[parentIndex]?.filters?.splice(filterIndex, 1);
      updateData("groups", newFilterGroups);
    },
    [updateData, groups]
  );

  const updateInnerFilter = useCallback(
    (parentIndex, filterIndex, value) => {
      const newFilterGroups = [...(groups || [])];
      newFilterGroups[parentIndex].filters[filterIndex].value = value;
      updateData("groups", newFilterGroups);
    },
    [groups, updateData]
  );

  const handleElementSelection = useCallback(
    (elementType, id) => {
      setState({
        selectedId: id,
        selectedType: elementType,
      });
    },
    [setState]
  );

  const filterGroupsElements = useMemo(() => {
    return (
      <div>
        {groups && groups.length > 0 ? (
          groups.map((filterGroup, index) => {
            const {
              filters,
              draggableId: uuid,
              filterType,
              groupInternalName = "",
            } = filterGroup;

            return (
              <Draggable
                key={uuid}
                draggableId={uuid}
                index={index}
                isDragDisabled={selectedType !== "group" && selectedId !== uuid}
              >
                {(provided) => (
                  <CardWrapper
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <FilterGroupWrapper
                      onClick={() => handleElementSelection("group", uuid)}
                      isSelected={uuid === selectedId}
                    >
                      <ExpansionPanel
                        label={`${groupInternalName || filterType} (${
                          filters?.length || 0
                        })`}
                        style={{ margin: 0, background: "white" }}
                        action={{
                          actionLabel: "Remove",
                          handler: () => removeFilterGroup(index),
                          status: "alert",
                        }}
                      >
                        <FormBuilder
                          fields={[
                            {
                              name: "groupInternalName",
                              label: "Group name",
                              type: "text",
                              required: true,
                              information:
                                "Required. For internal control only",
                            },
                          ]}
                          data={filterGroup}
                          updateData={(name, value) =>
                            updateFilterGroup(name, value, index)
                          }
                        />
                        <Label
                          style={{
                            marginRight: "auto",
                            width: "auto",
                            padding: "0",
                          }}
                        >
                          Filters
                        </Label>
                        <InnerFilters
                          filters={filters}
                          filterType={filterType}
                          parentIndex={index}
                          addInnerFilter={addInnerFilter}
                          removeInnerFilter={removeInnerFilter}
                          updateInnerFilter={updateInnerFilter}
                          onDragEndInnerFilters={onDragEndInnerFilters}
                          handleElementSelection={handleElementSelection}
                          selectedId={selectedId}
                          selectedType={selectedType}
                        />
                      </ExpansionPanel>
                    </FilterGroupWrapper>
                  </CardWrapper>
                )}
              </Draggable>
            );
          })
        ) : (
          <Label>Add first group</Label>
        )}
      </div>
    );
  }, [
    removeFilterGroup,
    updateFilterGroup,
    groups,
    addInnerFilter,
    removeInnerFilter,
    updateInnerFilter,
    handleElementSelection,
    onDragEndInnerFilters,
    selectedType,
    selectedId,
  ]);

  return (
    <>
      <ContentPanel label={"Filters"}>
        <DragDropContext onDragEnd={onDragEndFilterGroup}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Container {...provided.droppableProps} ref={provided.innerRef}>
                {filterGroupsElements}
                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        </DragDropContext>
      </ContentPanel>
      <Divider />
      <ContentPanel label={"Translations"}>
        {groups && groups.length > 0 ? (
          groups.map((filterGroup, index) => {
            const {
              translations = [],
              groupInternalName = "",
              filterType,
            } = filterGroup;

            const label = groupInternalName || filterType;

            return (
              <ExpansionPanel
                key={`grouptranslations-${label}-${index}`}
                label={`${label} (${translations?.length || 0})`}
                style={{ background: "white" }}
              >
                <TranslationsPanel
                  fields={[
                    {
                      name: "language",
                      label: "Language",
                      type: "relation",
                      collection: "languages",
                      required: true,
                      information: "Required.",
                      half: true,
                      id: `group-language-translations-${index}-${label}`,
                      debounceTime: 300,
                    },
                    {
                      name: "groupTitle",
                      label: "Group Title",
                      type: "text",
                      required: true,
                      information: "The UI represention of the group name",
                      half: true,
                      id: `group-title-translations-${index}-${label}`,
                    },
                  ]}
                  translations={translations}
                  setState={parentSetState}
                  updateData={(name, value) =>
                    updateFilterGroup(name, value, index)
                  }
                />
              </ExpansionPanel>
            );
          })
        ) : (
          <Label>Add first group</Label>
        )}
      </ContentPanel>
      <AddGroupWrapper>
        <FilterGroupTypes>
          {FILTER_GROUPS.map((filterGroup) => {
            const { icon: Icon, label, type } = filterGroup;
            return (
              <FilterGroupSelector
                onClick={() => addFilterGroup(type)}
                key={type}
              >
                <Icon fill={"#011f3b"} />
                <Label marginTop="0px">{label}</Label>
              </FilterGroupSelector>
            );
          })}
        </FilterGroupTypes>
        <AddGroupButton type="button" />
        <Label> Add filter group </Label>
      </AddGroupWrapper>
    </>
  );
};

export default FilterPage;

export const gqlQuery = `
  query {
    slug
    groups {
      draggableId,
      filterType,
      filters {
        draggableId,
        value
      }
      groupInternalName
      translations {
        language {
          _id,
          internalName,
          code
        }
        groupTitle
      }
    }
    signature {
      name,
      date
    }
  }
`;
