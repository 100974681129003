import React from "react";
import PropTypes from "prop-types";

import {
  Backdrop,
  ModalWrapper,
  Title,
  Body,
  Footer,
  Button,
} from "./Components";

const Modal = ({
  open = false,
  title = "",
  body = "",
  onContinue = () => {},
  onCancel = () => {},
}) => {
  return (
    <Backdrop open={open} onClick={onCancel}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <Title>{title}</Title>
        <Body dangerouslySetInnerHTML={{ __html: body }} />
        <Footer>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onContinue();
            }}
          >
            Ok
          </Button>
        </Footer>
      </ModalWrapper>
    </Backdrop>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.node.isRequired,
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Modal;
