import React from "react";
import { Wrapper, Title, Text } from "./Components";

const NotFound = () => {
  return (
    <Wrapper>
      <Title>404: Not Found</Title>
      <Text>You just hit a route that doesn't exist... the sadness :(</Text>
    </Wrapper>
  );
};

export default NotFound;
