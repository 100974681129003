import React, { useContext } from "react";
import { Col } from "./Components";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import AccessPanel from "@components/AccessPanel/AccessPanel";
import { AdminContext } from "@context/AdminContext";

const AdminForm = ({ updateData, data, documentId, entitiesData, action }) => {
  const { state: adminData } = useContext(AdminContext);

  const isOwn = documentId === adminData?.admin?._id;

  return (
    <>
      <Col order="first">
        <FormBuilder
          fields={[
            {
              type: "text",
              name: "username",
              label: "Username",
              required: true,
              half: false,
              information: "This is a required field",
            },
            {
              type: "email",
              name: "email",
              label: "Email",
              required: true,
              half: false,
              information: "This is a required field and must be unique",
            },
            {
              type: "password",
              name: "password",
              label: "Password",
              half: false,
              required: action === "edit" ? false : true,
              information:
                action === "edit" ? null : "This is a required field",
            },
          ]}
          updateData={updateData}
          data={data}
        />
      </Col>
      <Col>
        <FormBuilder
          fields={[
            {
              type: "boolean",
              name: "blocked",
              label: "Blocked",
              half: false,
              hide: isOwn,
            },
            {
              type: "image",
              name: "avatar",
              label: "Avatar",
              half: false,
            },
          ]}
          updateData={updateData}
          data={data}
        />
      </Col>
      {!isOwn && (
        <AccessPanel
          entitiesData={entitiesData}
          access={data.access}
          updateData={updateData}
        />
      )}
    </>
  );
};

export default AdminForm;

export const gqlQuery = `
    query {
      username
      blocked
      email
      access {
        read,
        create,
        delete,
        update
      }
      avatar {
        url,
        _id,
        name,
        ext
      }
      signature {
        name,
        date
      }
      _id
    }
`;
