import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  left: 16px;
  align-items: center;
  top: 16px;
  z-index: 1;
`;
const IconWrapper = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

const IconLabel = styled.p`
  margin: 0 0 0 8px;
`;

const Select = styled.select`
  padding: 0.375rem 0.75rem 0.375rem 9px;
  margin-right: 8px;
  background: rgb(255, 255, 255);
  border: 1px solid #dfe0eb;
  height: 36px;
  font-size: 12px;
  border-radius: 3px;
`;

const Input = styled.input`
  padding: 0.375rem 0.75rem 0.375rem 9px;
  margin-right: 8px;
  background: rgb(255, 255, 255);
  border: 1px solid #dfe0eb;
  height: 36px;
  font-size: 12px;
  border-radius: 3px;
`;

const ApplyFilters = styled.button`
  height: 36px;
  color: #ffffff;
  background-color: #3751ff;
  border: none;
  border-radius: 3px;
  padding: 0.375rem 1.375rem;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
`;

const CloseFilters = styled.button`
  border-radius: 50%;
  border: 1px solid #dfe0eb;
  background-color: #fff;
  color: #f12b2c;
  font-weight: 600;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  cursor: pointer;
  font-size: 12px;
  outline: none;

  &:hover {
    border: 1px solid #f12b2c;
  }

  &:focus,
  :visited,
  :active {
    outline: none;
  }
`;

export {
  IconWrapper,
  Wrapper,
  IconLabel,
  Select,
  Input,
  CloseFilters,
  ApplyFilters,
};
