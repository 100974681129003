import React from "react";
import TableBadge from "@components/TableBadge/TableBadge";
import TableImage from "@components/TableImage/TableImage";
import TableTranslations from "@components/TableTranslations/TableTranslations";
import { Link, Wrapper } from "./Components";
import { Launch } from "@icons";

const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

export const innerQueryFields = {
  Languages: ["code", "_id", "internalName"],
  Admins: ["username", "email", "blocked", "role", "_id"],
  Regions: ["_id", "internalName", "name", "image { _id, url }"],
  Counties: ["_id", "internalName", "name", "image { _id, url }"],
  Districts: ["_id", "internalName", "name", "image { _id, url }"],
  Locations: ["_id", "internalName", "name", "image { _id, url }"],
  Typologies: ["_id", "internalName", `translations { language { code } } `],
  Amenities: [
    "_id",
    "internalName",
    `icon { _id, url }`,
    `translations { language { code } } `,
  ],
  Tags: [
    "_id",
    "internalName",
    "image { _id, url }",
    "translations { language{ code } }",
  ],
  Accommodations: [
    "_id",
    "internalName",
    "thumbnail { _id, url }",
    "translations { language{ code } }",
    "active",
    "slug",
    `county {
      _id,
      internalName,
      slug,
      district {
        slug
      },
      region {
        slug
      }
    }`,
  ],
};

export const tableColumns = {
  Admins: [
    {
      name: "Name",
      selector: (row) => row["username"],
      id: "username",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Email",
      selector: (row) => row["email"],
      id: "email",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row["role"],
      id: "role",
    },
    {
      name: "Blocked",
      selector: (row) => row["blocked"],
      id: "blocked",
      sortable: true,
      cell: (row) =>
        row.blocked ? (
          <TableBadge status="alert">True</TableBadge>
        ) : (
          <TableBadge status="success">False</TableBadge>
        ),
    },
  ],
  Languages: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Code",
      selector: (row) => row["code"],
      id: "code",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
  ],
  Amenities: [
    {
      name: "Icon",
      cell: (row) => <TableImage url={row.icon?.url} />,
      selector: (row) => row.icon?.url,
      sortable: false,
    },
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
  ],
  Tags: [
    {
      name: "Image",
      cell: (row) => <TableImage url={row.image?.url} />,
      selector: (row) => row.image?.url,
      sortable: false,
    },
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
  ],
  Typologies: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
  ],
  Counties: [
    {
      name: "Image",
      cell: (row) => <TableImage url={row.image?.url} />,
      selector: (row) => row.image?.url,
      sortable: false,
    },
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      id: "name",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
  ],
  Districts: [
    {
      name: "Image",
      cell: (row) => <TableImage url={row.image?.url} />,
      selector: (row) => row.image?.url,
      sortable: false,
    },
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      id: "name",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
  ],
  Regions: [
    {
      name: "Image",
      cell: (row) => <TableImage url={row.image?.url} />,
      selector: (row) => row.image?.url,
      sortable: false,
    },
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      id: "name",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
  ],
  Locations: [
    {
      name: "Image",
      cell: (row) => <TableImage url={row.image?.url} />,
      selector: (row) => row.image?.url,
      sortable: false,
    },
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Name",
      selector: (row) => row["name"],
      id: "name",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
  ],
  Accommodations: [
    {
      name: "Internal name",
      selector: (row) => row["internalName"],
      cell: (row) => (
        <>
          <Link
            active={
              !!(
                row.county &&
                row.county.region &&
                row.county.district &&
                row.active
              )
            }
            href={
              row.county && row.county.region && row.county.district
                ? `${CLIENT_URL}/accommodations/${row.slug}`
                : null
            }
            target="_blank"
          >
            {row.internalName}
          </Link>
          {row.county &&
            row.county.region &&
            row.county.district &&
            row.active && (
              <Wrapper>
                <Launch width="11px" height="11px" fill={"rgb(37,39,51)"} />
              </Wrapper>
            )}
        </>
      ),
      id: "internalName",
      sortable: true,
      defaultSort: true,
      sortDirection: "asc",
    },
    {
      name: "Thumbnail",
      cell: (row) => <TableImage url={row.thumbnail?.url} />,
      selector: (row) => row.thumbnail?.url,
      sortable: false,
    },
    {
      name: "Translations",
      selector: (row) => row["translations"],
      id: "translations",
      sortable: false,
      cell: (row) => <TableTranslations translations={row.translations} />,
    },
    {
      name: "Active",
      selector: (row) => row["active"],
      id: "active",
      sortable: true,
      cell: (row) =>
        row.active ? (
          <TableBadge status="success">True</TableBadge>
        ) : (
          <TableBadge status="alert">False</TableBadge>
        ),
    },
  ],
};
