import { React, useState, useEffect } from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

const TRANSLATIONS_FIELDS = [
  {
    name: "language",
    type: "relation",
    collection: "languages",
    label: "Language",
    half: true,
    information: "This is a required field",
    required: true,
    debounceTime: 300,
  },
  {
    name: "promotion",
    type: "text",
    label: "Hotel promotions",
    information: "Hotel promotions or campaigns",
    half: true,
  },
  {
    name: "description",
    type: "richtext",
    label: "Hotel description",
    required: true,
    information: "It's a required field.",
    half: true,
  },
];

const AccomodationForm = ({ updateData, data, setState, action, modify }) => {
  const { translations = [], _id } = data;
  const { county, mainAmenities, amenities } = data;
  const { region, district } = county || {};

  const [countyModified, setCountyModified] = useState(data.county);

  useEffect(() => {
    if (countyModified === undefined) {
      setCountyModified(data.county);
    } else if (countyModified?._id !== data.county?._id) {
      setCountyModified(data.county);
      setState({ data: { ...data, location: null } });
    }
  }, [data.county, countyModified, setState, data]);

  const [active, setActive] = useState(data?.active || null);

  useEffect(() => {
    if (modify === false) setActive(data.active);
  }, [data.active, modify]);

  useEffect(() => {
    if (mainAmenities && amenities) {
      const newMainAmenities = mainAmenities.filter((mainAmenity) => {
        let result = false;

        let i = 0;

        while (i < amenities.length) {
          if (mainAmenity._id === amenities[i]._id) {
            result = true;
            i = amenities.length;
          }
          i++;
        }

        return result;
      });

      if (mainAmenities.length !== newMainAmenities.length) {
        setState({ data: { ...data, mainAmenities: newMainAmenities } });
      }
    }
  }, [amenities, setState, mainAmenities, data]);

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "linkToFront",
            type: "link",
            half: false,
            url:
              county && region && district
                ? `${CLIENT_URL}/accommodations/${data.slug}`
                : "",
            hide: !active || action === "create",
            disabled: modify || !(county && region && district),
            title: "Link to Public Page",
          },
          {
            name: "active",
            type: "boolean",
            label: "Active",
            information: "If false, accommodation will not be shown to public",
            half: true,
          },
          {
            name: "ahpchecked",
            type: "boolean",
            label: "AHP Checked",
            information: "When set to true, the AHP Checked badge will appear",
            half: true,
          },
          {
            name: "internalName",
            type: "text",
            label: "Internal name",
            required: true,
            information: "Required field. Only used for internal control.",
            half: true,
          },
          {
            name: "name",
            type: "text",
            label: "Accommodation name",
            required: true,
            information: "Required field.",
            half: true,
          },
          {
            name: "slug",
            type: "slug",
            label: "Hotel slug",
            information: "Required field. Used for the url.",
            half: true,
            required: true,
          },
          {
            name: "c2pId",
            type: "text",
            label: "C2P Acommodation ID",
            half: true,
            information: "C2P ID. Only used for internal control.",
          },
          {
            name: "tyId",
            type: "text",
            label: "TrustYou ID",
            half: true,
            information: "TrustYou ID of the accomodation.",
          },
          {
            name: "tyScore",
            type: "number",
            label: "TrustYou Score",
            information: "TrustYou Score of the accommodation: Read only.",
            half: true,
            disabled: true,
          },
          {
            name: "tyReviewsCount",
            type: "number",
            label: "TrustYou Reviews Count",
            information:
              "TrustYou reviews count of the accommodation: Read only.",
            half: true,
            disabled: true,
          },
          {
            name: "stars",
            type: "number",
            min: 0,
            max: 5,
            label: "Number of stars",
            information: "From 0 to 5",
            half: true,
          },
          {
            name: "typology",
            type: "relation",
            collection: "typologies",
            label: "Accomodation type",
            information: "Typology of the accommodation",
            half: true,
          },
          {
            type: "number",
            step: "0.0000001",
            half: true,
            label: "Latitude",
            name: "lat",
            information: "Latitude of the accommodation for Google Maps",
          },
          {
            type: "number",
            step: "0.0000001",
            half: true,
            label: "Longitude",
            name: "lng",
            information: "Longitude of the accommodation for Google Maps",
          },
          {
            type: "relation",
            half: true,
            collection: "counties",
            label: "County of accommodation",
            name: "county",
            information: "The county to where the accomodation belongs",
            queryItems:
              "items { _id, internalName, slug, locations { _id, internalName, slug } }",
          },
          {
            type: "sync-relations",
            half: true,
            collection: "locations",
            label: "Location of accommodation",
            name: "location",
            information: "The location to where the accomodation belongs",
            related: data.county?.locations || [],
            multiple: false,
          },
          {
            type: "email",
            half: true,
            label: "Accommodation email",
            name: "email",
            information: "Contact email of the accommodation",
          },
          {
            type: "phone",
            half: true,
            prefix: "(+351)",
            label: "Accommodation telephone",
            name: "phone",
            information: "Contact phone of the accommodation",
          },
          {
            type: "url",
            half: true,
            label: "Accommodation website",
            name: "website",
            information: "Website of the accommodation",
          },
          {
            type: "url",
            half: true,
            label: "Booking url of the accommodation",
            name: "bookingUrl",
            information:
              "User will be redirect to this url when clicking on the CTA",
          },
          {
            type: "textarea",
            half: true,
            label: "Accommodation RNT",
            name: "RNT",
            information: "RNT of the accommodation",
          },
          {
            type: "textarea",
            half: true,
            label: "Address",
            name: "address",
            information: "Full address of the accommodation",
          },
          {
            name: "amenities",
            type: action === "create" ? "sync-relations" : "relations",
            label: "All amenities",
            collection: "amenities",
            half: true,
            information: "All amenities. It can repeat with main amenities",
            debounceTime: 200,
          },
          {
            name: "mainAmenities",
            type: "sync-relations",
            label: "Main amenities",
            collection: "amenities",
            half: true,
            information: "These are the main amenities of the accommodation.",
            debounceTime: 300,
            related: data?.amenities || null,
          },
          {
            name: "tags",
            type: "relations",
            label: "All tags",
            collection: "tags",
            half: true,
            information: "Tags to find accomodations on filter page",
            debounceTime: 300,
          },
          {
            name: "thumbnail",
            type: "image",
            label: "Accomodation thumbnail",
            half: true,
            information: "The image of the accommodation card",
          },
          {
            name: "gallery",
            type: "gallery",
            label: "Accomodation gallery",
            half: false,
            information: "The image gallery of the accommodation",
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        translations={translations}
        updateData={updateData}
        setState={setState}
        parentId={_id}
        type="Accommodation"
        fields={TRANSLATIONS_FIELDS}
      />
    </>
  );
};

export default AccomodationForm;

export const gqlQuery = `
    query {
      _id
      internalName,
      name,
      c2pId,
      tyId,
      slug,
      tyScore,
      tyReviewsCount,
      email,
      website,
      bookingUrl,
      phone,
      stars,
      lng,
      lat,
      address,
      active,
      ahpchecked,
      RNT,
      tags {
        internalName,
        _id
      }
      translations {
        _id
        language {
          internalName,
          _id
        }     
        promotion,   
        description,        
      }
      amenities {
         _id,
         internalName,
      }
      mainAmenities {
        _id,
        internalName,
      }
      county {
        _id,
        internalName,
        slug,
        district {
          _id,
          internalName,
          slug,
        },
        region {
          _id,
          internalName,
          slug,
        }
        locations {
          _id,
          internalName,
          slug,
        }
      }
      location {
        _id,
        internalName,
        slug,
      }
      typology {
        _id,
        internalName,
      }
      thumbnail {
        url,
        _id
      }
      gallery {
        url,
        _id
      }
      signature {
        name,
        date
      }
    }
`;
