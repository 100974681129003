import React from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { buildUrl } from "@utils/misc";
import NotFoundDefault from "../NotFound/NotFound";

const RoutesBuilder = ({
  routesProps,
  rootPath,
  notFoundComponent: NotFound,
}) => {
  try {
    return (
      <Switch>
        {(routesProps || []).map((routeProps) => {
          const { component: Component, path, exact, ...props } = routeProps;

          const url = buildUrl([rootPath, path]);

          try {
            return (
              <Route
                {...props}
                exact={exact}
                path={url}
                render={(routerProps) => {
                  return <Component {...routerProps} rootPath={rootPath} />;
                }}
              />
            );
          } catch (e) {
            console.error(e);
            return null;
          }
        })}
        <Route
          render={(props) => {
            if (NotFound) {
              return <NotFound {...props} />;
            } else {
              return <NotFoundDefault />;
            }
          }}
        />
      </Switch>
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};

RoutesBuilder.propTypes = {
  routesProps: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      exact: PropTypes.bool,
      component: PropTypes.func,
    }).isRequired
  ),
  rootPath: PropTypes.string.isRequired,
  notFoundComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.instanceOf(undefined),
  ]),
};

export default RoutesBuilder;
