import React, { useState, useEffect } from "react";
import { Image } from "@icons";
import { Img, IconWrapper } from "./Components";
import PropTypes from "prop-types";
import LazyLoad from "@utils/lazyLoad";

const TableImage = ({ url }) => {
  const [loadedUrl, setLoadedUrl] = useState(null);

  useEffect(() => {
    const lazyLoader = new LazyLoad();

    (async () => {
      if (!url) return;
      const loadedUrl = await lazyLoader.load(url);
      if (loadedUrl) {
        setLoadedUrl(url);
      }
    })();

    return () => {
      lazyLoader.cancel();
    };
  }, [url]);

  return !url ? (
    <IconWrapper>
      <Image fill="rgb(158, 167, 184)" />
    </IconWrapper>
  ) : (
    <Img src={loadedUrl} />
  );
};

TableImage.propTypes = {
  url: PropTypes.string,
};

export default TableImage;
