import React from "react";
import { ButtonWrapper, Ellipse, Result } from "./Components";
import PropTypes from "prop-types";

const BooleanButton = ({
  value = false,
  onChange = () => {},
  disabled = false,
  size = "md",
  id,
}) => {
  return (
    <ButtonWrapper
      id={id}
      disabled={disabled}
      status={value}
      type="button"
      onClick={() => !disabled && onChange && onChange(!value)}
      size={size}
    >
      <Ellipse status={value} size={size} />
      <Result size={size}>{value ? "TRUE" : "FALSE"}</Result>
    </ButtonWrapper>
  );
};

BooleanButton.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["md", "sm"]),
  id: PropTypes.string,
};

export default BooleanButton;
