import React from "react";

const LogoLoader = () => {
  return (
    <img
      src="/images/C2P_logo_blue.svg"
      className="logo-loader animation-loader-pulse"
      width="210px"
      alt="c2p logo"
    />
  );
};

export default LogoLoader;
