import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

const AmenityForm = ({ updateData, data, setState }) => {
  const { translations = [], _id } = data;

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "internalName",
            type: "text",
            label: "Internal name",
            required: true,
            information: "Required field. Only used for internal control.",
            half: true,
          },
          {
            name: "slug",
            type: "slug",
            label: "slug",
            half: true,
            required: true,
            information: "Required field. A unique identifier of the typology",
          },
          {
            name: "group",
            type: "relation",
            label: "Amenity group",
            collection: "Tags",
            half: true,
            required: true,
            information: "To which group the amenity belongs",
          },
          {
            name: "icon",
            type: "image",
            label: "Amenity icon",
            information: "Icon to be shown together with the amenity",
            half: true,
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        translations={translations}
        updateData={updateData}
        type="Amenity"
        parentId={_id}
        setState={setState}
        fields={[
          {
            name: "language",
            type: "relation",
            collection: "languages",
            label: "Language",
            half: true,
            information: "This is a required field",
            required: true,
            debounceTime: 300,
          },
          {
            name: "name",
            type: "text",
            label: "Amenity name",
            required: true,
            information: "Required Field. UI representation of the amenity.",
            half: true,
          },
        ]}
      />
    </>
  );
};

export default AmenityForm;

export const gqlQuery = `
  query {
    _id
    icon {
      url,
      _id
    }
    internalName,
    slug,
    group {
      internalName,
      _id
    }
    translations {
        _id,
        name,
        language {
          _id,
          internalName
        }
    }
    signature {
      name,
      date
    }
  }
`;
