import styled from "styled-components";

const DropZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  align-items: center;
  padding: 30px;
  background-color: #fff;
  border: 1px solid rgb(223, 224, 235);
  border-radius: 5px;
  max-width: 768px;
  margin: 0 auto;
  transition: all 0.24s ease-in-out;
  &:hover {
    border: 1px solid transparent;
    box-shadow: 0px 18px 28px rgba(0, 0, 0, 0.16);
  }
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  margin: 0 0 8px 0;
  color: #252733;
`;

const Text = styled.p`
  margin: ${(props) => (props.error === "true" ? "12px 0 0 0" : "0")};
  text-align: center;
  color: ${(props) =>
    props.error === "true" ? "#FF3E1D" : "rgba(117, 117, 117, 1)"};
  font-weight: ${(props) => (props.error === "true" ? "600" : "400")};
  font-size: ${(props) => (props.error === "true" ? "12px" : "14px")};
  line-height: 18px;
`;

export { DropZoneWrapper, Text, Title };
