import styled from "styled-components";

const Dropzone = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #e0e0e0;
  border-color: ${(props) => {
    if (props.isDragAccept) {
      return "#00e676";
    }
    if (props.isDragReject) {
      return "#FF3E1D";
    }
    if (props.isDragActive) {
      return "#2196f3";
    }
    return "#e0e0e0";
  }};
  border-radius: 5px;
  transition: border 0.24s ease-in-out;
  outline: none;
  cursor: pointer;
  color: #bdbdbd;
`;

const Text = styled.p`
  margin: 12px 0 0 0;
  text-align: center;
  color: ${(props) => (props.error ? "#FF3E1D" : "rgba(117, 117, 117, 1)")};
  font-weight: ${(props) => (props.error ? "600" : "400")};
  font-size: 14px;
  line-height: 18px;
`;

export { Dropzone, Text };
