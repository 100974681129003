import styled from "styled-components";

const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 250px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.section`
  padding-bottom: 24px;
`;

const FormWrapper = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  width: 100%;
  padding: 40px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(227 233 243) 0px 2px 4px;
  margin-bottom: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 8px 16px;
  text-decoration: none;
  color: #ffffff;
  background-color: ${(props) =>
    props.action === "delete" ? "#f12b2c" : "#3751ff"};
  font-size: 13px;
  margin-left: auto;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-left: 16px;
  opacity: ${(props) => (props.disabled ? "0.6" : "1")};
`;

const Signature = styled.p`
  color: #a4a6b3;
  font-size: 12px;
  font-style: italic;
  & * {
    color: #a4a6b3;
  }
`;

export { FormWrapper, Wrapper, Header, Form, Button, LoaderWrapper, Signature };
