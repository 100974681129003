import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

const TypologyForm = ({ updateData, data, setState }) => {
  const { translations = [], _id } = data;

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "internalName",
            type: "text",
            label: "Internal name",
            required: true,
            information: "Required field. Only used for internal control.",
            half: true,
          },
          {
            name: "slug",
            type: "slug",
            label: "Typology slug",
            required: true,
            information: "Required field. A unique identifier of the typology",
            half: true,
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        translations={translations}
        updateData={updateData}
        setState={setState}
        parentId={_id}
        type="Typology"
        fields={[
          {
            name: "language",
            type: "relation",
            collection: "languages",
            label: "Language",
            half: true,
            information: "This is a required field",
            required: true,
            debounceTime: 300,
          },
          {
            name: "name",
            type: "text",
            label: "Typology name",
            required: true,
            information: "Required Field. UI representation of the typology.",
            half: true,
          },
        ]}
      />
    </>
  );
};

export default TypologyForm;

export const gqlQuery = `
  query {
    _id
    internalName,
    slug,
    translations {
       _id,
       name,
       language {
         _id,
         internalName
       }
    }
    signature {
      name,
      date
    }
  }
`;
