import React from "react";

const Next = ({ width = "8px", height = "14px", fill = "#2C2C2C" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6.99996C8 6.64636 7.85952 6.30716 7.60948 6.05716L2.27613 0.723825C1.75547 0.203159 0.9112 0.203159 0.390533 0.723825C-0.130134 1.24449 -0.130134 2.08876 0.390533 2.60943L4.78107 6.99996L0.390533 11.3905C-0.130134 11.9112 -0.130134 12.7554 0.390533 13.2761C0.9112 13.7968 1.75547 13.7968 2.27613 13.2761L7.60948 7.94276C7.85952 7.69276 8 7.35356 8 6.99996Z"
        fill={fill}
      />
    </svg>
  );
};

export default Next;
