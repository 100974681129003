import {
  Accomodation,
  Typology,
  Amenity,
  Tag,
  Language,
  Location,
  Gallery,
  WYSIWYG,
  Description,
  Account,
  Sort,
  Policy,
  Home,
  ImportExport,
} from "@icons";

import { appendKeys } from "@utils/misc";

export const entities = [
  {
    to: "/accommodations",
    label: "Accommodations",
    icon: Accomodation,
    component: "Accommodation",
  },
  {
    to: "/amenities",
    label: "Amenities",
    icon: Amenity,
    component: "Amenity",
  },
  {
    to: "/typologies",
    label: "Typologies",
    icon: Typology,
    component: "Typology",
  },
  {
    to: "/tags",
    label: "Tags",
    icon: Tag,
    component: "Tag",
  },
  {
    to: "/languages",
    label: "Languages",
    icon: Language,
    component: "Language",
  },
  {
    to: "/regions",
    label: "Regions",
    icon: Location,
    component: "Region",
  },
  {
    to: "/districts",
    label: "Districts",
    icon: Location,
    component: "District",
  },
  {
    to: "/counties",
    label: "Counties",
    icon: Location,
    component: "County",
  },
  {
    to: "/locations",
    label: "Locations",
    icon: Location,
    component: "Location",
  },
].map(appendKeys);

export const services = [
  {
    to: "/gallery",
    label: "Upload Images",
    icon: Gallery,
  },
  {
    to: "/static-pages",
    label: "Content Management",
    icon: WYSIWYG,
  },
  {
    to: "/import-export",
    label: "Import & Export data",
    icon: ImportExport,
  },
].map(appendKeys);

export const pages = [
  {
    to: "/homepage",
    label: "Home page",
    icon: Home,
    component: "Homepage",
  },
  {
    to: "/filter",
    label: "Filter Page",
    icon: Sort,
    component: "Filterpage",
  },
  {
    to: "/about",
    label: "About Us page",
    icon: Account,
    component: "Aboutpage",
  },
  {
    to: "/terms-and-conditions",
    label: "T&C Page",
    icon: Description,
    component: "Termspage",
  },
  {
    to: "/privacy-policy",
    label: "Privacy Policy Page",
    icon: Policy,
    component: "Privacypage",
  },
].map(appendKeys);
