import React, { useEffect, useMemo, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import io from "socket.io-client";

import FileDropZone from "@components/FileDropZone/FileDropZone";
import GalleryList from "@components/GalleryList/GalleryList";
import ImageModal from "@components/ImageModal/ImageModal";
import Snackbar from "@components/Snackbar/Snackbar";
import Modal from "@components/Modal/Modal";

import { useSetState } from "@hooks/";

import { GalleryContext } from "@context/GalleryContext";

import request from "@utils/request/";
import { Title, Wrapper } from "./Components";

const PAGE_LENGTH = 12;
const apiUrl = process.env.REACT_APP_API_URL;

const deleteImageFile = async (image) => {
  const {
    data: { deleteImage },
  } = await request(`
    mutation {
      deleteImage (
        _id: "${image?._id}"
      ) {
        ok,
        eventId
      }
    }
  `);

  const { eventId } = deleteImage;

  const socket = io(apiUrl, {
    query: {
      roomId: eventId,
    },
  });

  return new Promise((resolve, reject) => {
    socket.on(eventId, (status) => {
      const { ok } = status;

      if (ok === true) {
        resolve({ ok });
      } else {
        reject("Something went wrong");
      }

      socket.disconnect();
    });
  });
};

const Gallery = ({
  withSelection = false,
  onChangeImageSelection = () => {},
  selectedImageId = "",
}) => {
  const {
    state: { searchValue },
    dispatch: searchDispatch,
  } = useContext(GalleryContext);

  const [state, setState] = useSetState({
    images: null,
    count: null,
    pageIndex: 1,
    loading: true,
    searchTimoutId: null,
    sortQuery: "_id:desc",
    skipQuery: 0,
    searchQuery: searchValue,
    imageToDelete: null,
    firstFetch: true,
    languages: null,
    alertModal: {
      open: false,
      body: "",
      title: "",
    },
    modalData: {
      show: false,
      file: null,
    },
    snackbarData: {
      show: false,
      message: "",
      error: false,
    },
  });

  const {
    images,
    count,
    pageIndex,
    searchTimoutId,
    sortQuery,
    skipQuery,
    searchQuery,
    modalData,
    snackbarData,
    alertModal,
    loading,
    firstFetch,
    languages,
  } = state;

  const extractErrorMessage = (e) => {
    try {
      let errorMessage = e.message || "Something went wrong. Plase, try again";

      const { isAxiosError } = e;

      if (isAxiosError) {
        errorMessage =
          e.response.data.errors && e.response.data.errors[0].message;
      }

      return errorMessage;
    } catch (e) {
      return "Something went wrong";
    }
  };

  const handleError = useCallback(
    (e) => {
      console.error(e);
      const errorMessage = extractErrorMessage(e);
      setState({
        loading: false,
        snackbarData: {
          show: true,
          error: true,
          message: errorMessage,
        },
      });
    },
    [setState]
  );

  const getImages = useCallback(
    async (
      sort = "_id:desc",
      skip = 0,
      searchQuery = "",
      firstFetch = false
    ) => {
      setState({
        loading: true,
      });

      try {
        const {
          data: { allImages, allLanguages },
        } = await request(`
          query {
            allImages (
              input: {
                sort: "${sort}"
                limit: ${PAGE_LENGTH}
                skip: ${skip}
                search: "${searchQuery}"
              }
            ) {
              images {
                name,
                ext,
                url,
                key,
                _id,
                createdAt,
                size,
                hasThumbnail,
                altText {
                  value
                  language {
                    code,
                    _id
                  }
                }
              }
              count
            }

            ${
              firstFetch
                ? `allLanguages(input: {
                page: 1,
                limit: 100
              }
              ){
                count, 
                items {
                  _id,
                  code
                }
              }`
                : ""
            }
          }
        `);
        const { images, count } = allImages;

        const languages = state.languages || allLanguages?.items;

        setState({
          images: images,
          count: count,
          loading: false,
          firstFetch: false,
          languages,
        });
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, state.languages, setState]
  );

  useEffect(() => {
    getImages(sortQuery, skipQuery, searchQuery, firstFetch);
  }, [sortQuery, skipQuery, searchQuery, firstFetch, getImages]);

  const onViewImage = (file) => {
    setState({
      modalData: {
        show: true,
        file,
      },
    });
  };

  const onUpdateImage = async (newFile) => {
    try {
      const { _id, ...body } = newFile;

      const {
        data: { updateImage },
      } = await request(
        `
        mutation update ($input: ImageInput!) { 
          updateImage (input: $input) {
            ok,
            status,
            message
          }
        }
      `,
        { input: { _id: _id, name: body.name, altText: body.altText } }
      );

      const { ok } = updateImage;

      if (ok === true) {
        getImages(sortQuery, skipQuery, searchQuery);
      }

      setState({
        snackbarData: {
          show: true,
          error: !ok,
          message: ok ? "Image has been updated." : "Something went wrong",
        },
        modalData: {
          show: false,
          file: null,
        },
      });
    } catch (e) {
      setState({
        modalData: {
          show: false,
          file: null,
        },
      });
      handleError(e);
    }
  };

  const onCloseModal = () => {
    setState({
      modalData: {
        show: false,
        file: null,
      },
    });
  };

  const onCloseSnackbar = () => {
    setState({
      snackbarData: {
        show: false,
        error: snackbarData.error,
        message: snackbarData.message,
      },
    });
  };

  const onConfirmDeleteImage = async (image, status) => {
    setState({
      alertModal: {
        open: status,
        body: "This is an irreversible action. Are you sure you want to delete this image?",
        title: "You are about to delete this image!",
      },
      imageToDelete: image,
    });
  };

  const onDeleteImage = async () => {
    try {
      setState({
        loading: true,
        alertModal: {
          open: false,
          body: "",
          title: "",
        },
      });

      await deleteImageFile(state.imageToDelete);

      setState({
        snackbarData: {
          show: true,
          error: false,
          message: "Image has been deleted.",
        },
        loading: false,
      });

      getImages(sortQuery, skipQuery, searchQuery);
    } catch (e) {
      handleError(e);
      setState({
        loading: false,
      });
    }
  };

  const addImageToTheList = () => {
    getImages("_id:desc", 0, "");
    setState({
      pageIndex: 1,
    });
  };

  const onSortChange = ({ target: { value } }) => {
    switch (value) {
      case "oldest":
        setState({
          sortQuery: "_id:asc",
        });
        break;
      case "newest":
        setState({
          sortQuery: "_id:desc",
        });
        break;
      case "largest":
        setState({
          sortQuery: "size:desc",
        });
        break;
      case "smallest":
        setState({
          sortQuery: "size:asc",
        });
        break;
      default:
      //
    }
  };

  const onPagination = useCallback(
    (selectedIndex) => {
      const skipQuery = (selectedIndex - 1) * PAGE_LENGTH;
      setState({
        pageIndex: selectedIndex,
        skipQuery: skipQuery,
      });
    },
    [setState]
  );

  const onSearchImage = useCallback(
    ({ target: { value } }) => {
      try {
        clearTimeout(searchTimoutId);

        const ID = setTimeout(async () => {
          setState({
            skipQuery: 0,
            pageIndex: 1,
            searchQuery: value,
          });

          searchDispatch("search", value);
        }, 1000);

        setState({
          searchTimoutId: ID,
        });
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, searchTimoutId, searchDispatch, setState]
  );

  const nrOfPages = useMemo(
    () => Math.ceil((count || 0) / PAGE_LENGTH),
    [count]
  );

  return (
    <Wrapper>
      <Title> Image Gallery </Title>
      <FileDropZone onSuccess={addImageToTheList} />
      <GalleryList
        imagesList={images}
        loadingImages={loading}
        nrOfPages={nrOfPages}
        pageIndex={pageIndex}
        onPagination={onPagination}
        withSelection={withSelection}
        selectedImageId={selectedImageId}
        defaultSearchValue={searchValue}
        actions={{
          onView: onViewImage,
          onDelete: (image) => onConfirmDeleteImage(image, true),
          onSearch: onSearchImage,
          onSort: onSortChange,
          onChangeSelection: onChangeImageSelection,
        }}
      />
      <ImageModal
        modalData={modalData}
        onHide={onCloseModal}
        onSave={onUpdateImage}
        languages={languages}
        onError={(e) => {
          handleError(e);
        }}
      />
      <Modal
        open={alertModal.open}
        title={alertModal.title}
        body={alertModal.body}
        onCancel={() => onConfirmDeleteImage(null, false)}
        onContinue={onDeleteImage}
      />
      <Snackbar
        open={snackbarData.show}
        onClose={onCloseSnackbar}
        autoHideDuration={3000}
        status={snackbarData.error ? "alert" : "success"}
        message={snackbarData.message}
      />
    </Wrapper>
  );
};

Gallery.propTypes = {
  withSelection: PropTypes.bool,
  onChangeImageSelection: PropTypes.func,
  selectedImageId: PropTypes.string,
};

export default Gallery;
