import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";

const RegionForm = ({ data, updateData }) => {
  return (
    <FormBuilder
      fields={[
        {
          name: "internalName",
          label: "Internal name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. Only used for internal control.",
        },
        {
          name: "name",
          label: "Region name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. UI representation of the region.",
        },
        {
          name: "slug",
          label: "Slug",
          half: true,
          type: "slug",
          required: true,
          information: "Required field. A unique identifier of the region.",
        },
        {
          name: "districts",
          label: "Districts",
          half: true,
          type: "relations",
          collection: "districts",
          required: true,
          information: "The districts that belong to this region.",
        },
        {
          name: "image",
          label: "Region image",
          half: true,
          type: "image",
          information: "A representative image of the region.",
        },
      ]}
      data={data}
      updateData={updateData}
    />
  );
};

export default RegionForm;

export const gqlQuery = `
  query {
    internalName
    name
    slug
    image{
      url,
      _id
    }
    districts {
      internalName,
      _id
    }
    signature {
      name,
      date
    }
    _id
  }
`;
