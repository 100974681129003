import React from "react";
import { Badge } from "./Components";
import PropTypes from "prop-types";

const TableBadge = ({ status, children }) => {
  return <Badge status={status}>{children}</Badge>;
};

TableBadge.propTypes = {
  status: PropTypes.oneOf(["default", "alert", "success", "warning"])
    .isRequired,
  children: PropTypes.string.isRequired,
};

export default TableBadge;
