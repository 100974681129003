import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OutterBorder = styled.div`
  border: 2px solid #ffffff;
  margin-left: ${(props) => (props.first ? "0px" : "-12px")};
  border-radius: 38px;
  position: relative;
  z-index: 1;

  &:hover {
    z-index: 2;
  }
`;

const Translation = styled.div`
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  border: 1px solid #363740;
  text-align: center;
  line-height: 36px;
  font-size: 9px;
  border-radius: 36px;
  background: #ffffff;
  font-weight: 700;
`;

export { Translation, Wrapper, OutterBorder };
