import React from "react";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";
import Title from "@components/Title/Title";
import Modal from "@components/Modal/Modal";
import Snackbar from "@components/Snackbar/Snackbar";
import moment from "moment";
import {
  FormWrapper,
  Wrapper,
  Header,
  Form,
  Button,
  LoaderWrapper,
  Signature,
} from "./Components";

const emptyFunc = () => {};

const FormLayout = ({
  actions = {
    remove: { onRemove: emptyFunc, canRemove: false, buttonText: "" },
    save: { onSave: emptyFunc, canSave: false, buttonText: "" },
  },
  modal = {
    open: false,
    body: "",
    title: "",
    onModalChange: emptyFunc,
  },
  snackbar = {
    open: false,
    error: false,
    message: "",
    onClose: emptyFunc,
  },
  signature = { name: "", date: new Date() },
  loading = false,
  containerTitle = "",
  children,
  disable = false,
}) => {
  const { remove = {}, save = {} } = actions;

  const { onRemove = emptyFunc, canRemove, buttonText: delButtonText } = remove;
  const { onSave = emptyFunc, canSave, buttonText: saveButtonText } = save;
  const { open: modalOpen, body, title, onModalChange = emptyFunc } = modal;
  const { open: snackOpen, error, message, onClose = emptyFunc } = snackbar;

  return (
    <Wrapper>
      <Header>
        <div>
          {containerTitle && <Title>{containerTitle}</Title>}
          {signature?.name && signature?.date && (
            <Signature>
              last edited by
              <b> {signature?.name} </b>
              on
              <b> {moment(signature?.date).format("YYYY-MM-DD @ hh:mm:ss")} </b>
            </Signature>
          )}
        </div>
        <div>
          {canRemove && (
            <Button
              action="delete"
              onClick={() => onModalChange(true)}
              disabled={disable}
            >
              {delButtonText}
            </Button>
          )}
          {canSave && (
            <Button
              action="save"
              type="submit"
              form="entity-form"
              disabled={disable}
            >
              {saveButtonText}
            </Button>
          )}
        </div>
      </Header>
      {loading && (
        <LoaderWrapper>
          <Loader type="TailSpin" color="#00BFFF" height={60} width={60} />
        </LoaderWrapper>
      )}
      <FormWrapper show={!loading}>
        <Form onSubmit={onSave} id="entity-form">
          {children}
        </Form>
      </FormWrapper>
      <Modal
        open={modalOpen}
        body={body}
        title={title}
        onContinue={onRemove}
        onCancel={() => onModalChange(false)}
      />
      <Snackbar
        open={snackOpen}
        status={error ? "alert" : "success"}
        title={error ? "Error" : "Success"}
        message={message}
        autoHideDuration={3000}
        onClose={onClose}
      />
    </Wrapper>
  );
};

FormLayout.propTypes = {
  loading: PropTypes.bool,
  disable: PropTypes.bool,
  children: PropTypes.node,
  containerTitle: PropTypes.string,
  actions: PropTypes.shape({
    remove: PropTypes.shape({
      onRemove: PropTypes.func,
      buttonText: PropTypes.node,
      canRemove: PropTypes.bool,
    }),
    save: PropTypes.shape({
      onSave: PropTypes.func,
      buttonText: PropTypes.node,
      canSave: PropTypes.bool,
    }),
  }),
  modal: PropTypes.shape({
    open: PropTypes.bool,
    body: PropTypes.node,
    title: PropTypes.string,
    onModalChange: PropTypes.func,
  }),
  signature: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.string,
  }),
  snackbar: PropTypes.shape({
    open: PropTypes.bool,
    error: PropTypes.bool,
    message: PropTypes.string,
    onClose: PropTypes.func,
  }),
};

export default FormLayout;
