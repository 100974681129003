import React, { useEffect } from "react";
import Select from "react-select";
import { useSetState, useCapitalizedName } from "@hooks";
import { HiddenInput, Wrapper } from "./Components";
import request from "@utils/request/";

const SyncRelations = ({
  collection,
  value,
  onChangeSelection,
  required,
  id,
  relatedData,
  multi = true,
}) => {
  const [state, setState] = useSetState({
    items: null,
    loading: false,
  });

  const { items, loading } = state;

  const collectionName = useCapitalizedName(collection);
  const queryName = ` all${collectionName}`;

  useEffect(() => {
    if (relatedData === null) {
      (async () => {
        try {
          const { data } = await request(`
          query {
            ${queryName}(
              input: {
                page: 1,
                limit: 100
              }
            ) {
              items {
                _id,
                internalName
                slug
              }
            }
          }
        `);

          const { items } = data[queryName.trim()];

          setState({
            items: items,
            loading: false,
          });
        } catch (e) {
          console.error("e", e);
          setState({
            items: [],
            loading: false,
          });
        }
      })();
    } else {
      setState({
        items: relatedData,
        loading: false,
      });
    }
  }, [queryName, setState, relatedData]);

  return (
    <Wrapper>
      <HiddenInput
        required={required}
        defaultValue={value ? (value.length > 0 ? "placeholder" : "") : ""}
      />
      <Select
        options={items}
        isLoading={loading}
        id={id}
        value={value}
        getOptionLabel={(option) => option.internalName}
        getOptionValue={(option) => option._id}
        onChange={onChangeSelection}
        isMulti={multi}
        isClearable={true}
        styles={{
          container: (styles) => ({
            ...styles,
            zIndex: 2,
          }),
          control: (styles) => ({
            ...styles,
            border: "1px solid #e0e0e0",
            maxHeight: "180px",
            overflow: "auto",
            borderRadius: "2px",
            "&:hover": {
              border: "1px solid #e0e0e0",
            },
            zIndex: 2,
          }),
          multiValue: (styles) => {
            return {
              ...styles,
              backgroundColor: "rgba(55,81,255,0.85)",
              padding: "4px 8px",
              borderRadius: "4px",
            };
          },
          multiValueLabel: (styles) => ({
            ...styles,
            color: "#f7faff",
            fontSize: "12px",
            fontWeight: 600,
            padding: "0",
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            cursor: "pointer",
            ":hover": {
              backgroundColor: "transparent",
            },
            "& > svg": {
              fill: "#f7faff",
            },
          }),
        }}
      />
    </Wrapper>
  );
};

export default SyncRelations;
