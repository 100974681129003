import React, { memo, useMemo } from "react";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import BooleanButton from "../BooleanButton/BooleanButton";
import { Header, Row, Cell } from "./Components";

const extractCollection = (access, action) => [...(access[action] || [])];

const findInCollections = (collections, collectionToChange) =>
  collections?.findIndex((collection) => collectionToChange === collection);

const AccessPanel = ({ access = {}, entitiesData, updateData }) => {
  const rows = useMemo(() => {
    const onChange = (collectionToChange, action) => {
      let newAccess = { ...access };
      let collections = [...(access[action] || [])];

      const index = findInCollections(collections, collectionToChange);

      if (action === "read" && index !== -1) {
        collections.splice(index, 1);

        const deleteCollections = extractCollection(access, "delete");
        const updateCollections = extractCollection(access, "update");
        const createCollections = extractCollection(access, "create");

        const deleteIndex = findInCollections(
          deleteCollections,
          collectionToChange
        );

        const updateIndex = findInCollections(
          updateCollections,
          collectionToChange
        );

        const createIndex = findInCollections(
          createCollections,
          collectionToChange
        );

        if (deleteIndex !== -1) {
          deleteCollections.splice(deleteIndex, 1);
        }

        if (updateIndex !== -1) {
          updateCollections.splice(updateIndex, 1);
        }

        if (createIndex !== -1) {
          createCollections.splice(createIndex, 1);
        }

        newAccess["create"] = createCollections;
        newAccess["delete"] = deleteCollections;
        newAccess["update"] = updateCollections;
      } else if (index !== -1) {
        collections.splice(index, 1);
      } else {
        collections.push(collectionToChange);
      }

      newAccess[action] = collections;

      updateData("access", newAccess);
    };

    return entitiesData.map((entity) => {
      if (entity.component && entity.component !== "Admin") {
        const collection = pluralize(entity.component.toLowerCase());
        const canRead = access.read?.includes(collection);
        const canDelete = access.delete?.includes(collection);
        const canUpdate = access.update?.includes(collection);
        const canCreate = access.create?.includes(collection);

        return (
          <Row key={entity.key}>
            <Cell first>{collection.toUpperCase()}</Cell>
            <Cell onClick={() => onChange(collection, "read")}>
              <BooleanButton value={canRead || false} />
            </Cell>
            <Cell onClick={() => onChange(collection, "create")}>
              <BooleanButton value={canCreate || false} disabled={!canRead} />
            </Cell>
            <Cell onClick={() => onChange(collection, "update")}>
              <BooleanButton value={canUpdate || false} disabled={!canRead} />
            </Cell>
            <Cell onClick={() => onChange(collection, "delete")}>
              <BooleanButton value={canDelete || false} disabled={!canRead} />
            </Cell>
          </Row>
        );
      } else {
        return null;
      }
    });
  }, [entitiesData, access, updateData]);

  return (
    //Had to do inline style because when passing cellSpacing to a styled component
    //it didn't work properly
    <table
      width="100%"
      cellSpacing="0"
      style={{
        border: "1px solid #dfe0eb",
        borderRadius: "4px",
        borderBottom: "none",
        marginTop: "8px",
      }}
    >
      <thead>
        <Row>
          <Header first>
            <b>Access Panel</b>
          </Header>
          <Header>Read</Header>
          <Header>Create</Header>
          <Header>Update</Header>
          <Header>Delete</Header>
        </Row>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

AccessPanel.propTypes = {
  entitiesData: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  access: PropTypes.shape({
    read: PropTypes.arrayOf(PropTypes.string),
    delete: PropTypes.arrayOf(PropTypes.string),
    update: PropTypes.arrayOf(PropTypes.string),
    create: PropTypes.arrayOf(PropTypes.string),
  }),
  updateData: PropTypes.func.isRequired,
};

export default memo(AccessPanel);
