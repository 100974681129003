import React, { useEffect, useState } from "react";
import { Wrapper, Frame, Toolbar, ActionWrapper, Checkbox } from "./Components";
import { Delete, Visibility } from "@icons";
import { getThumbnailUrl } from "@utils/misc";
import LazyLoad from "@utils/lazyLoad";
import PropTypes from "prop-types";

const ImageContainer = ({
  image = {},
  actions = {},
  withSelection = false,
  selectedImageId = "",
}) => {
  const [loadedUrl, setLoadedUrl] = useState(null);

  useEffect(() => {
    const lazyLoader = new LazyLoad();

    (async () => {
      const url = getThumbnailUrl(image);
      if (!url) return;
      const loadedUrl = await lazyLoader.load(url);
      if (loadedUrl) {
        setLoadedUrl(url);
      }
    })();

    return () => {
      lazyLoader.cancel();
    };
  }, [image]);

  const {
    onView = () => {},
    onDelete = () => {},
    onChangeSelection = () => {},
  } = actions;

  const onClick = (imageSelected) => {
    if (withSelection === true && onChangeSelection) {
      if (!imageSelected) {
        onChangeSelection(image);
      } else {
        onChangeSelection(null);
      }
    }
  };

  const isSelected = (image, selectedImageId) => image._id === selectedImageId;
  const imageSelected = withSelection && isSelected(image, selectedImageId);

  return (
    <Wrapper
      selectable={withSelection}
      onClick={() => onClick(imageSelected)}
      isSelected={imageSelected}
    >
      <Toolbar isSelected={imageSelected}>
        {withSelection && (
          <Checkbox
            type={"checkbox"}
            defaultChecked={imageSelected}
            key={imageSelected}
          />
        )}
        <ActionWrapper
          onClick={(e) => {
            e.stopPropagation();
            onView(image);
          }}
        >
          <Visibility
            width={"21px"}
            height={"21px"}
            fill={"rgb(158, 167, 184)"}
          />
        </ActionWrapper>
        {!withSelection && (
          <ActionWrapper onClick={() => onDelete(image)}>
            <Delete
              width={"21px"}
              height={"21px"}
              fill={"rgb(158, 167, 184)"}
            />
          </ActionWrapper>
        )}
      </Toolbar>
      <Frame src={loadedUrl} isSelected={imageSelected.toString()} />
    </Wrapper>
  );
};

ImageContainer.propTypes = {
  image: PropTypes.object,
  withSelection: PropTypes.bool,
  selectedImageId: PropTypes.string,
  actions: PropTypes.shape({
    onView: PropTypes.func,
    onDelete: PropTypes.func,
  }),
};

export default ImageContainer;
