import React from "react";
import { useParams } from "react-router-dom";
import clone from "lodash/clone";

import FormBuilder from "../FormBuilder/FormBuilder";
import ExpansionPanel from "../ExpansionPanel/ExpansionPanel";
import ContentPanel from "../ContentPanel/ContentPanel";
import request from "@utils/request";
import { extractErrorMessage } from "@utils/misc";
import { useAccess } from "@hooks";
import { Circle, Button } from "./Components";

const TranslationsPanel = ({
  translations = [],
  updateData = () => {},
  setState,
  fields = [],
  parentId = "",
  type = "",
}) => {
  const params = useParams();
  const { canUpdate, canCreate } = useAccess(params?.entity);

  const canDeleteTranslations = params?.id ? canUpdate : canCreate;

  const updateTranslation = (name, value, index) => {
    const newTranslationsArr = clone(translations);
    const translation = newTranslationsArr[index] || {};
    newTranslationsArr[index] = { ...translation, [name]: value };
    updateData(
      "translations",
      newTranslationsArr.filter((t) => t !== null)
    );
  };

  const onAddNewTranslation = () => {
    const newTranslations = [...(translations || []), {}];
    updateData(
      "translations",
      newTranslations.filter((t) => t !== null)
    );
  };

  const onRemoveTranslation = async (_id, index) => {
    try {
      if (!canDeleteTranslations) return;

      const nrOfTranslations = (translations || []).length;
      const isTranslationSaved = !!_id;

      if (nrOfTranslations <= 1)
        throw new Error("You cannot have 0 translations");

      const proceed = !isTranslationSaved
        ? true
        : window.confirm("Are you sure you want to remove this translation?");

      if (proceed === false) return;

      if (isTranslationSaved && type) {
        await request(`
        mutation {
          delete${type}Translation (input: {
            translationId: "${_id}",
            parentId: "${parentId}"
          }) {
            ok, 
            status,
            message
          }
        }
      `);
      }

      const newTranslations = [...translations];
      newTranslations.splice(index, 1);

      updateData("translations", newTranslations);
    } catch (e) {
      //TODO: Change it to a callback onError to propagate the error to the parent
      //So we don't need to pass the setState as a prop
      const message = extractErrorMessage(e);
      console.error("error", e.message);
      setState &&
        setState({
          submitted: {
            error: true,
            status: true,
            message: message,
          },
        });
    }
  };

  return (
    <ContentPanel label={"Translations"} key={translations?.length}>
      {(translations || [])
        .filter((t) => t !== null)
        .map((translation = {}, index) => {
          const { _id } = translation;

          return (
            <ExpansionPanel
              label={translation?.language?.internalName || "New translation"}
              key={`translations-${index}`}
              action={
                canDeleteTranslations
                  ? {
                      status: "alert",
                      handler: () => onRemoveTranslation(_id, index),
                      actionLabel: "Delete",
                    }
                  : null
              }
            >
              <FormBuilder
                fields={fields}
                data={translation}
                updateData={(name, value) =>
                  updateTranslation(name, value, index)
                }
              />
            </ExpansionPanel>
          );
        })}
      <Button type="button" onClick={onAddNewTranslation}>
        <Circle>+</Circle> <span>Add new translation</span>
      </Button>
    </ContentPanel>
  );
};

export default TranslationsPanel;
