import React, { useEffect } from "react";
import slugify from "slugify";
import { Input } from "./Components";

import { useSetState } from "../../hooks";

const SlugInput = ({ onSlugify, defaultValue, ...inputProps }) => {
  const [state, setState] = useSetState({
    value: defaultValue || "",
    updatedOnMount: false,
  });

  const { value, updatedOnMount } = state;

  useEffect(() => {
    if (!updatedOnMount && defaultValue !== undefined) {
      setState({ value: defaultValue, updatedOnMount: true });
    }
  }, [defaultValue, updatedOnMount, setState]);

  const onBlur = () => {
    const slugifiedValue = slugify(value, {
      lower: true,
      replacement: "-",
    });

    setState({
      value: slugifiedValue,
    });

    onSlugify && onSlugify(slugifiedValue);
  };

  return (
    <Input
      value={value}
      onChange={({ target: { value } }) => setState({ value })}
      onBlur={onBlur}
      placeholder={"Write what you want to slugify"}
      {...inputProps}
    />
  );
};

export default SlugInput;
