import React, {
  useCallback,
  useEffect,
  useContext,
  useMemo,
  useState,
} from "react";
import { Prompt } from "react-router-dom";
import { useCapitalizedName, useSetState, useAccess } from "@hooks/";
import NotFound from "@components/NotFound/NotFound";
import FormLayout from "@layouts/FormLayout/FormLayout";
import { entities } from "@config/navigation";
import request from "@utils/request";
import { generateKeys, parseGqlQuery, extractErrorMessage } from "@utils/misc";
import { AdminContext } from "@context/AdminContext";

const entitiesData = [
  ...entities,
  {
    component: "Admin",
    key: generateKeys(1)[0],
  },
];

const entityComponents = entitiesData.reduce((result, entity) => {
  const component = entity.component;
  try {
    if (component) {
      const module = require(`./pages/${component}/${component}.jsx`);
      result[component] = {
        component: module.default,
        query: parseGqlQuery(module.gqlQuery),
      };
    }
    return result;
  } catch (e) {
    result[component] = {
      component: null,
      query: "",
    };
    return result;
  }
}, {});

const CRUD = ({ entity, action, match, history, ...props }) => {
  const entityTitle = useCapitalizedName(entity, false);
  const collectionTitle = useCapitalizedName(entity, true);
  const containerTitle = useCapitalizedName(`${action} ${entity}`, false);
  const { state: adminData, dispatch } = useContext(AdminContext);
  const Entity = useMemo(
    () => entityComponents[entityTitle]?.component || null,
    [entityTitle]
  );

  const documentId = match?.params?.id;
  const collection = match?.params?.entity;
  const editMode = action === "edit";
  const isAdmin = entity === "admin";
  const isOwnAdmin = isAdmin && documentId === adminData?.admin?._id;

  const { canUpdate, canCreate, canDelete, canRead } = useAccess(collection);

  const accessData = useMemo(() => {
    return {
      write: editMode ? isOwnAdmin || canUpdate : canCreate,
      delete: editMode && !isOwnAdmin && canDelete,
    };
  }, [isOwnAdmin, editMode, canUpdate, canCreate, canDelete]);

  const [state, setState] = useSetState({
    data: {},
    loading: editMode ? true : false,
    setTimeoutId: null,
    gqlQuery: entityComponents[entityTitle].query,
    fetched: false,
    touched: false,
    saving: false,
    submitted: {
      status: false,
      error: false,
      message: "",
    },
    modal: {
      open: false,
      body: "",
      title: "",
    },
  });

  const [modify, setModify] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const fetched = state.fetched;
      const gqlQuery = state.gqlQuery;
      const getQuery = `
        query {
          ${entity}
          (_id: "${documentId}") 
          ${gqlQuery}
        }
      `;

      if (editMode && !fetched && getQuery && (isOwnAdmin || canRead)) {
        try {
          const { data } = await request(getQuery);

          setState({
            data: data[entity] || {},
            fetched: true,
            loading: false,
          });
        } catch (e) {
          console.error(e);
          setState({ fetched: true, loading: false });
        }
      }
    };

    getData();
  }, [
    editMode,
    setState,
    state.fetched,
    state.gqlQuery,
    entity,
    documentId,
    canRead,
    isOwnAdmin,
  ]);

  useEffect(() => {
    return () => clearTimeout(state.setTimeoutId);
  }, [state.setTimeoutId]);

  const updateData = useCallback(
    (field, value) => {
      let newData = { ...state.data, [field]: value };
      setState({ data: newData, touched: true });
      setModify(true);
    },
    [setState, state.data]
  );

  const updateState = useCallback(
    (newState) => {
      setModify(true);
      setState(newState);
    },
    [setModify, setState]
  );

  const onSave = async (e) => {
    try {
      e.preventDefault();

      setState({
        saving: true,
      });

      const gqlQuery = state.gqlQuery;

      const writeQuery = `
        mutation write ($input: ${entityTitle}Input!) {
          ${action}${entityTitle} (input: $input) 
          ${gqlQuery}
        }`;

      const dataToSave = { ...state.data };
      delete dataToSave["signature"];

      const { data } = await request(writeQuery, { input: dataToSave });

      setModify(false);

      setState({
        touched: false,
        saving: false,
        submitted: {
          status: true,
          error: false,
          message: `${entity} ${editMode ? "edited" : "created"} sucessfully`,
        },
      });

      const entityData = data[`${action}${entityTitle}`];

      if (editMode && !isOwnAdmin) {
        return setState({ data: entityData });
      }

      if (!editMode) {
        const ID = setTimeout(() => {
          history.push({
            pathname: `/dashboard/${collection}/edit/${entityData._id}`,
          });
        }, 1000);

        return setState({ setTimeoutId: ID });
      }

      if (isOwnAdmin) {
        const ID = setTimeout(() => {
          dispatch("write", entityData);
        }, 1000);

        return setState({ setTimeoutId: ID });
      }
    } catch (e) {
      console.error("e", e);
      const message = extractErrorMessage(e);
      setState({
        saving: false,
        submitted: {
          status: true,
          error: true,
          message: message,
        },
      });
    }
  };

  const onRemove = async () => {
    try {
      await request(`
        mutation {
          delete${collectionTitle} (
            input: ${JSON.stringify([documentId])}
          ) {
            ok,
            message,
            status
          }
        }
      `);

      history.push({
        pathname: `/dashboard/${collection}`,
      });
    } catch (e) {
      console.error("e", e);
      const message = extractErrorMessage(e);
      setState({
        submitted: {
          status: true,
          error: true,
          message: message,
        },
        modal: {
          open: false,
          title: state.modal.title,
          body: state.modal.body,
        },
      });
    }
  };

  const onModalChange = (isOpen) => {
    if (isOpen) {
      setState({
        modal: {
          open: true,
          title: `You are about to delete this ${entity}`,
          body: "This action is irreversible. Are you sure you want to proceed?",
        },
      });
    } else {
      setState({
        modal: {
          open: false,
          title: state.modal.title,
          body: state.modal.body,
        },
      });
    }
  };

  const onCloseSnackbar = () => {
    setState({
      submitted: {
        error: false,
        status: false,
        message: state.submitted.message,
      },
    });
  };

  const { data, submitted, loading, modal, saving, touched } = state;

  if (!canRead && !isOwnAdmin) {
    return null;
  }

  if (Entity) {
    return (
      <>
        <Prompt
          when={touched}
          message="You are about to leave this form. Everything that wasn't saved will be lost. Are you sure you want to proceed?"
        />
        <FormLayout
          containerTitle={containerTitle}
          loading={loading}
          disable={loading || saving}
          signature={editMode && !isOwnAdmin ? data?.signature : null}
          actions={{
            remove: {
              canRemove: accessData.delete,
              buttonText: `${"Delete"} ${entity}`,
              onRemove: onRemove,
            },
            save: {
              canSave: accessData.write,
              onSave: onSave,
              buttonText: `${editMode ? "Save" : "Create"} ${entity}`,
            },
          }}
          modal={{
            open: modal.open,
            title: modal.title,
            body: modal.body,
            onModalChange: onModalChange,
          }}
          snackbar={{
            open: submitted.status,
            error: submitted.error,
            message: submitted.message,
            onClose: onCloseSnackbar,
          }}
        >
          <Entity
            entity={entity}
            entitiesData={entitiesData}
            updateData={updateData}
            setState={updateState}
            data={data}
            modify={modify}
            action={action}
            documentId={documentId}
            {...props}
          />
        </FormLayout>
      </>
    );
  } else {
    return <NotFound />;
  }
};

export default CRUD;
