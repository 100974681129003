import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

const Aboutpage = ({
  data = {},
  updateData = () => {},
  setState = () => {},
}) => {
  const { translations = [] } = data;

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "images",
            type: "gallery",
            half: false,
            label: "Images",
            information: "Max of 3 (three) images",
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        fields={[
          {
            type: "relation",
            name: "language",
            label: "Language",
            collection: "languages",
            half: true,
            information: "It's a required field",
            required: true,
          },
          {
            type: "richtext",
            name: "topTextSection",
            label: "Top Text Section",
            half: false,
            information: "The text at the top of the page",
          },
          {
            type: "richtext",
            name: "bottomTextSection",
            label: "Bottom Text Section",
            half: false,
            information: "The text at the bottom of the page",
          },
        ]}
        type={"Aboutpage"}
        updateData={updateData}
        setState={setState}
        translations={translations}
      />
    </>
  );
};

export default Aboutpage;

export const gqlQuery = `
  query {
    slug
    images {
      url,
      _id,
    }
    translations {
      language {
        _id,
        internalName,
        code
      }
      topTextSection,
      bottomTextSection
    }
    signature {
      name,
      date
    }
  }
`;
