import React from "react";
import { MainTitle } from "./Components";
import PropTypes from "prop-types";

const Title = ({ children }) => {
  return <MainTitle> {children} </MainTitle>;
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Title;
