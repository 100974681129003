import styled from "styled-components";

const ThreeDots = styled.div`
  &::before {
    font-size: 13px;
    padding: 4px;
    content: "...";
  }
`;

const Item = styled.div`
  font-size: 13px;
  line-height: 14px;
  padding: 4px;
  cursor: pointer;
  font-weight: 600;
  color: ${(props) => (props.active ? "#18A0FB" : "#2C2C2C")};
  text-decoration: ${(props) => (props.active ? "underline" : "none")};
  & span {
    font-size: 1rem !important;
  }
  & a {
    line-height: 20px !important;
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowWrapper = styled.div`
  background-color: rgba(224, 224, 224, 0.3);
  border-radius: 36px;
  width: 24px;
  height: 24px;
  display: ${(props) => (props.disabled ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  margin: 0 4px;

  &:hover {
    background: ${(props) =>
      props.disabled ? "rgba(224, 224, 224, 0.3)" : "#18a0fb"};
  }

  &:hover path {
    fill: ${(props) => (props.disabled ? "#2C2C2C" : "#fff")};
  }
`;

export { Item, Pagination, ThreeDots, ArrowWrapper };
