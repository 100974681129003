import React from "react";
import EntitiyRoutes from "@components/EntityRoutes/EntityRoutes";
import Gallery from "@containers/Gallery/Gallery";
import ImportExport from "@containers/ImportExport/ImportExport";
import RoutesBuilder from "@components/RoutesBuilder/RoutesBuilder";
import BackofficeHeader from "@components/BackofficeHeader/BackofficeHeader";
import Navigation from "@components/Navigation/Navigation";
import CMSRoutes from "@components/CMSRoutes/CMSRoutes";
import { appendKeys } from "@utils/misc";
import {
  Wrapper,
  PageWrapper,
  MenuWrapper,
  Container,
  InnerWrapper,
} from "./Components";

const ROUTES = [
  {
    path: "/",
    exact: true,
    component: () => (
      <div>
        Welcome to <b>Click2Portugal</b> Backoffice{" "}
      </div>
    ),
  },
  {
    path: "/gallery",
    exact: true,
    component: Gallery,
  },
  {
    path: "/static-pages",
    exact: false,
    component: CMSRoutes,
  },
  {
    path: "/import-export",
    exact: false,
    component: ImportExport,
  },
  {
    path: "/:entity",
    exact: false,
    component: EntitiyRoutes,
  },
].map(appendKeys);

const Backoffice = (props) => {
  const {
    match: { path },
    location: { pathname },
  } = props;

  return (
    <Wrapper>
      <MenuWrapper>
        <BackofficeHeader path={path} />
        <Navigation path={path} pathname={pathname} />
      </MenuWrapper>
      <Container>
        <PageWrapper>
          <InnerWrapper>
            <RoutesBuilder routesProps={ROUTES} rootPath={path} />
          </InnerWrapper>
        </PageWrapper>
      </Container>
    </Wrapper>
  );
};

export default Backoffice;
