import React, { useEffect, useState, useMemo } from "react";
import Gallery from "@containers/Gallery/Gallery";
import { GalleryWrapper, ImageSelector, Modal } from "./Components";
import { getThumbnailUrl } from "@utils/misc";
import { AddImage } from "@icons";

const ImageUploader = ({
  initialImage = null,
  onImageSelection = () => {},
}) => {
  const [showGalleryModal, setShowGalleryModal] = useState(false);
  const [mount, setMounted] = useState(false);

  const onChangeImageSelection = (image) => {
    onImageSelection && onImageSelection(image);
    setShowGalleryModal(false);
  };

  useEffect(() => {
    if (!mount && showGalleryModal) {
      setMounted(true);
    }
  }, [mount, showGalleryModal]);

  const src = useMemo(() => getThumbnailUrl(initialImage), [initialImage]);

  return (
    <div>
      <ImageSelector
        onClick={() => setShowGalleryModal((prevState) => !prevState)}
        src={src || ""}
      >
        {!src && (
          <AddImage
            width={"60px"}
            height={"60px"}
            fill={"rgba(255,255,255,0.2)"}
          />
        )}
      </ImageSelector>
      <Modal
        show={showGalleryModal}
        onClick={() => {
          setShowGalleryModal(false);
        }}
      >
        {mount && (
          <GalleryWrapper
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Gallery
              withSelection
              onChangeImageSelection={onChangeImageSelection}
              selectedImageId={initialImage?._id || ""}
            />
          </GalleryWrapper>
        )}
      </Modal>
    </div>
  );
};

export default ImageUploader;
