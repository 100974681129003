import styled from "styled-components";

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  margin-right: 16px;
  margin-top: 16px;
  height: 36px;
`;

const Input = styled.input`
  border-radius: 3px;
  padding: 0.375rem 0.75rem 0.375rem 18px;
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid #dfe0eb;
  height: 36px;
  &::placeholder {
    color: #9fa2b4;
    line-height: 36px;
  }

  &:active,
  :focus,
  :visited {
    outline: none;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 4px;
  top: 6px;
`;

export { Input, InputWrapper, IconWrapper };
