import styled from "styled-components";

const ElementWrapper = styled.div`
  width: ${(props) => (props.half ? "calc(50% - 24px)" : "100%")};
  margin-bottom: 17px;
`;

const Label = styled.label`
  width: 100%;
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;
`;

const Link = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;
  text-align: right;
  cursor: ${(props) => (props.disabled ? "not-allowed;" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  text-decoration: ${(props) => (props.disabled ? "none" : "underline")};
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
`;

const Input = styled.input`
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  padding: 10px 12px;
  font-size: 13px;
  line-height: 16px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &::placeholder {
    font-size: 14px;
    color: #b2aba9;
    letter-spacing: 0;
    font-size: 13px;
    line-height: 44px;
    height: 44px;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px 12px;
  border-radius: 2px;
  resize: none;
  border: 1px solid #e0e0e0;
  height: 122px;
  &::placeholder {
    font-size: 14px;
    color: #b2aba9;
    letter-spacing: 0;
    font-size: 13px;
    line-height: 13px;
    height: 13px;
  }
`;

const Info = styled.small`
  color: #6c757d !important;
  font-weight: 400;
  font-size: 10px;
  margin-top: 4px;
  display: block;
  text-align: ${(props) => (props.type === "link" ? "right" : "unset")};
`;

export { ElementWrapper, Label, Input, Textarea, Info, Link };
