import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  padding: 0 6px 4px 6px;
  position: relative;
  max-height: none;
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: ${(props) => (props.isDragging ? "column" : "row")};
`;

const InputWrapper = styled.div`
  display: flex;
  flex: 1 1 0%;
  height: 32px;
  position: relative;
  border-radius: 2px;
  box-sizing: border-box;
  margin-top: 2px;
`;

const Input = styled.input`
  border: none;
  background-color: transparent;
  border-radius: 2px;
  width: 100%;
  padding: 10px 12px 12px 4px;
  font-size: 13px;
  line-height: 16px;
  color: #2c2c2c;
  position: relative;
  z-index: 1;
  height: 100%;
  &:focus,
  :visited,
  :active {
    outline: none;
  }

  &::placeholder {
    font-size: 14px;
    color: #b2aba9;
    letter-spacing: 0;
    font-size: 13px;
    line-height: 32px;
    height: 32px;
  }
`;

const HiddenInput = styled.input`
  z-index: -1;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  padding: 0px;
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
`;

const CloseButton = styled.button`
  background-color: transparent;
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(0, -50%);
  border: none;
  cursor: pointer;
  height: 100%;
  width: 32px;
  font-weight: 600;
  font-size: 13px;
  z-index: 2;
`;

const ListWrapper = styled.div`
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 5px 5px;
  width: 100%;
  padding: 8px;
  z-index: 2;
  max-height: 130px;
  overflow: auto;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #e8f5ff;
  }
`;

const LoadWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NoResult = styled.p`
  padding: 8px;
  text-align: center;
`;

const Chip = styled.span`
  padding: 4px 8px;
  border-radius: 4px;
  color: #f7faff;
  font-size: 12px;
  display: block;
  margin: 6px 4px 0px 0px;
  cursor: default;
  font-weight: 600;
  background-color: ${(props) =>
    props.focused ? "rgb(55, 81, 255)" : "rgba(55, 81, 255, 0.85)"};
`;

const RemoveButton = styled.button`
  padding: 0 4px;
  color: inherit;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 11px;

  &:hover {
    font-weight: 800;
  }

  &:focus,
  :visited,
  :active {
    outline: none;
  }
`;

const ReorderLabel = styled.label`
  display: block;
  margin-right: 8px;
  color: #6c757d;
  font-size: 12px;
  font-weight: 500;
`;

const ToggleButtonWrapper = styled.div`
  position: absolute;
  top: -24px;
  right: 36px;
  display: flex;
  align-items: center;
`;

export {
  Input,
  CloseButton,
  Wrapper,
  ListWrapper,
  List,
  ListItem,
  LoadWrapper,
  NoResult,
  Container,
  InputWrapper,
  Chip,
  RemoveButton,
  HiddenInput,
  InnerContainer,
  ToggleButtonWrapper,
  ReorderLabel,
};
