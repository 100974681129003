import styled from "styled-components";

const Button = styled.button`
  width: 100%;
  padding: 8px;
  text-decoration: none;
  color: #3751ff;
  background-color: #ffffff;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  :visited,
  :active {
    outline: none;
  }
`;

const Circle = styled.span`
  min-width: 18px;
  min-height: 18px;
  border-radius: 18px;
  border: 1px solid #3751ff;
  background-color: #3751ff;
  color: #ffffff;
  display: block;
  max-width: 18px;
  max-height: 18px;
  line-height: 18px;
  padding: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
  outline: none;
  font-weight: 800;
`;

export { Button, Circle };
