import React, { createContext, useState } from "react";

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [state, setState] = useState({
    isAuth: undefined,
    admin: {},
  });

  const reducer = (action, data) => {
    switch (action) {
      case "login":
      case "write":
        setState({
          isAuth: true,
          admin: data,
        });
        break;
      case "logout":
        setState({
          isAuth: false,
          admin: {},
        });
        break;
      default:
      //
    }
  };

  return (
    <AdminContext.Provider
      value={{
        state,
        dispatch: reducer,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
