import styled from "styled-components";

const Link = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #252733;
  font-weight: 600;
  letter-spacing: 0.2px;
  font-size: 12px;
  &:hover {
    color: ${(props) => (props.active === true ? "#3751ff" : "unset")};
  }
`;

const Wrapper = styled.div`
  height: 12px;
  padding-left: 2px;
`;

export { Link, Wrapper };
