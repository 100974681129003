import styled from "styled-components";

const Badge = styled.div`
  border-radius: 100px;
  color: #ffffff !important;
  font-style: normal;
  font-weight: bold;
  font-size: 8px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 2px 8px;
  background-color: ${(props) => {
    switch (props.status) {
      case "alert":
        return "#F12B2C";
      case "success":
        return "#29CC97";
      case "warning":
        return "#FEC400";
      default:
        return "#C5C7CD";
    }
  }};
`;

export { Badge };
