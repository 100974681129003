import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";

const LanguageForm = ({ data, updateData }) => {
  return (
    <FormBuilder
      fields={[
        {
          name: "internalName",
          label: "Internal name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. Only used for internal control.",
        },
        {
          name: "code",
          label: "Code",
          half: true,
          type: "text",
          pattern: "[a-z]{1,8}(-[a-zA-Z0-9]{1,8})*",
          title: "Must follow ISO language code. Ex: pt, es, en-GB",
          required: true,
          information: "Required field. It must be unique.",
        },
      ]}
      data={data}
      updateData={updateData}
    />
  );
};

export default LanguageForm;

export const gqlQuery = `
  query {
    internalName
    code
    signature {
      name,
      date
    }
    _id
  }
`;
