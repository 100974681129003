class LazyLoad {
  constructor() {
    this.img = document.createElement("img");
    this.promise = null;
  }

  load = (url) => {
    const promise = new Promise((resolve) => {
      if (!url) {
        return resolve(true);
      }
      this.img.src = url;
      if (this.img.complete) {
        return resolve(true);
      }
      this.img.addEventListener("load", () => {
        resolve(true);
      });
    });

    this.promise = promise;
    return promise;
  };

  cancel = () => {
    this.img.src = "";
    Promise.resolve(this.promise);
  };
}

export default LazyLoad;
