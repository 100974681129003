import styled from "styled-components";

const Label = styled.label`
  font-weight: 600;
  font-size: 12.584px;
  color: rgb(51, 55, 64);
  cursor: pointer;
  padding-left: 8px;
  /* width: 100%; */
  text-align: center;
  display: block;
`;

const AddGroupButton = styled.button`
  padding: 0px;
  border-radius: 100%;
  text-align: center;
  width: 56px;
  height: 56px;
  border: none;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  letter-spacing: 0.02857em;
  cursor: pointer;
  background-color: #eaedf2;
  &::after {
    content: "+";
    line-height: 56px;
    font-size: 24px;
  }
`;

const FilterGroupTypes = styled.div`
  display: flex;
  cursor: pointer;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  visibility: hidden;
  opacity: 0;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: -64px;
`;

const FilterGroupSelector = styled.div`
  min-width: 150px;
  text-align: center;
  padding: 16px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: rgb(0 0 0 / 5%);
  }
`;

const AddGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  margin-top: 90px;

  &:hover > ${AddGroupButton} {
    transform: rotate(-135deg);
  }

  &:hover > ${FilterGroupTypes} {
    visibility: visible;
    opacity: 1;
  }
`;

const FilterGroupWrapper = styled.div`
  border-radius: 5px;
  padding: 0;
  max-width: 520px;
  width: 100vw;
  border: ${(props) => (props.isSelected ? "1px solid #1750FF" : "none")};
`;

const FilterGroupTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin: 0 0 24px 0;
  color: #282b31;
  text-transform: capitalize;
`;

const FilterWrapper = styled.div`
  display: flex;
  background: #ffffff;
  border-radius: 5px;
  width: 100vw;
  max-width: 462px;
  padding: 12px 24px;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  z-index: ${(props) => (props.isSelected ? 3 : 0)};
  border: ${(props) =>
    props.isSelected ? "1px solid #1750FF" : "1px solid #DDE1E8"};
`;

const FilterPosition = styled.div`
  padding: 16px 20px;
  background-color: #dde1e8;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #282b31;
  max-height: 50px;
  max-width: 50px;
  display: flex;
  justify-content: center;
  margin-right: 12px;
`;

const Button = styled.button`
  outline: none;
  padding: 4px 16px;
  color: #ffffff;
  background-color: #3751ff;
  border-radius: 5px;
  font-size: 13.5px;
  font-weight: 400;
  vertical-align: middle;
  max-width: 240px;
  margin-top: 18px;
  cursor: pointer;
  font-weight: 600;
  border: none;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1072px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardWrapper = styled.div`
  position: relative;
  margin: 4px 0;
  width: 100%;
`;

const DeleteButton = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  cursor: pointer;
  background-color: rgb(246, 77, 10);
  border-radius: 100%;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 12px;
  font-size: 18px;
  color: white;
  transition: all 240ms ease-in-out;
  z-index: 4;
  &:hover {
    font-weight: 600;
    transform: translate3d(0, -2px, 0);
  }

  &:active,
  &:focus,
  &:visited {
    outline: none;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 24px;
`;

export {
  Label,
  Button,
  Divider,
  Container,
  CardWrapper,
  DeleteButton,
  FilterWrapper,
  FilterPosition,
  AddGroupButton,
  AddGroupWrapper,
  FilterGroupTypes,
  FilterGroupTitle,
  FilterGroupWrapper,
  FilterGroupSelector,
};
