import React, { useEffect, useState } from "react";
import { Close } from "@icons";
import {
  Container,
  IconWrapper,
  MessageContainer,
  Message,
  Title,
} from "./Components";
import PropTypes from "prop-types";

const getStatusColor = (status) => {
  switch (status) {
    case "alert":
      return "#FF3E1D";
    case "warning":
      return "#FFD422";
    case "success":
      return "#00D200";
    default:
      return "#FFFFFF";
  }
};

const Snackbar = ({
  open = false,
  autoHideDuration = 0,
  onClose,
  message = "",
  status = "default",
  title = "Alert",
}) => {
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    let ID;

    if (open && autoHideDuration && onClose) {
      ID = setTimeout(onClose, autoHideDuration);
    }

    setTimeoutId(ID);

    return () => clearTimeout(ID);
  }, [open, onClose, autoHideDuration]);

  const onCloseSnackbar = () => {
    clearTimeout(timeoutId);
    onClose && onClose();
  };

  return (
    <Container open={open} color={getStatusColor(status)}>
      <IconWrapper onClick={onCloseSnackbar}>
        <Close
          fill={status === "default" ? "#E0E0E0" : "#FFFFFF"}
          color={getStatusColor(status)}
        />
      </IconWrapper>
      <MessageContainer>
        <Title status={status}>{title}</Title>
        <Message status={status}>{message}</Message>
      </MessageContainer>
    </Container>
  );
};

Snackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  message: PropTypes.string.isRequired,
  title: PropTypes.string,
  status: PropTypes.oneOf(["default", "warning", "alert", "success"]),
};

export default Snackbar;
