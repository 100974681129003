import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
  padding: 12px;
  transition: all 0.4s ease-in;
  margin-bottom: 8px;
  position: relative;
`;

const Label = styled.p`
  margin: 0;
  cursor: pointer;
  background-color: #fff;
  padding: 0 8px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;

  &::before {
    border-radius: inherit;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: box-shadow;
  }
`;

const Content = styled.div`
  height: ${(props) => (props.open ? "auto" : "0px")};
  width: 100%;
  overflow: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  padding: ${(props) => (props.open ? "24px 16px 16px 16px" : "0 16px")};
  transition: padding-top, padding-bottom 0.2s ease-in;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ActionButton = styled.button`
  border: ${(props) =>
    props.status === "alert" ? "1px solid #f12b2c" : "1px solid #3751ff"};
  background-color: #ffffff;
  color: ${(props) => (props.status === "alert" ? " #f12b2c" : " #3751ff")};
  font-weight: 600;
  border-radius: 5px;
  padding: 8px 16px;
  margin-right: 16px;
  cursor: pointer;
  outline: none;

  &:active,
  :focus,
  :visited {
    outline: none;
  }
`;

export { Wrapper, Content, Label, ActionButton };
