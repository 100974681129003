import React, { useState, useEffect } from "react";
import ImageUploader from "../ImageUploader/ImageUploader";
import Snackbar from "@components/Snackbar/Snackbar";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  Wrapper,
  List,
  Item,
  Button,
  DeleteButton,
  NoEntries,
  Text,
  Chip,
} from "./Components";
import nanoid from "nanoid";
import { AddImage } from "@icons";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgb(221, 221, 221)" : "rgb(247, 248, 248)",
  boxShadow: isDraggingOver
    ? "none"
    : "inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
  display: "flex",
  padding: grid,
  overflow: "auto",
  width: "100%",
  borderRadius: "4px",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 ${grid}px 0 0`,
  width: "100%",
  maxWidth: "320px",
  background: isDragging ? "lightgreen" : "grey",
  ...draggableStyle,
});

const MultipleImagesUploader = ({
  initialImages = [],
  onImagesChange = () => {},
}) => {
  const [images, setImages] = useState(initialImages);
  const [updatedOnMount, setUpdated] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({
    open: false,
    message: "",
  });

  useEffect(() => {
    if (!updatedOnMount && (initialImages || []).length !== 0) {
      setUpdated(true);
      setImages(initialImages);
    }
  }, [initialImages, updatedOnMount]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === 0) {
      const imageSelected = images[result.source.index];
      if (!imageSelected || !imageSelected.url) return;
    }

    const items = reorder(
      images,
      result.source.index,
      result.destination.index
    );

    setImages(items);
    onImagesChange(items);
  };

  const addOneToTheList = () => {
    const newList = [...images, { _id: nanoid() }];
    setImages(newList);
    onImagesChange(newList);
  };

  const onDeleteImage = (index) => {
    const clonedImages = [...images];
    clonedImages.splice(index, 1);
    setImages(clonedImages);
    onImagesChange(clonedImages);
  };

  const updateList = (index, image) => {
    const clonedImages = [...images];
    clonedImages[index] = image || { _id: nanoid() };
    setImages(clonedImages);
    onImagesChange(clonedImages);
  };

  const handleImageSelection = (selectedImage, index) => {
    let repeated = false;
    images.forEach((image) => {
      if (image._id === selectedImage._id) {
        repeated = true;
      }
    });
    if (repeated) {
      setShowSnackbar({
        open: true,
        message: "Image already exists in the gallery",
      });
    } else {
      updateList(index, selectedImage);
    }
  };

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => {
            return (
              <List
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {(images || []).length > 0 ? (
                  images.map((image, index) => {
                    return (
                      <Draggable
                        key={image?._id}
                        draggableId={"dragable-id-" + image?._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Item
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {index === 0 && <Chip> Main Image </Chip>}
                            <DeleteButton onClick={() => onDeleteImage(index)}>
                              X
                            </DeleteButton>
                            <ImageUploader
                              initialImage={image}
                              onImageSelection={(selectedImage) =>
                                handleImageSelection(selectedImage, index)
                              }
                            />
                          </Item>
                        )}
                      </Draggable>
                    );
                  })
                ) : (
                  <NoEntries>
                    <AddImage
                      fill={"rgb(158,167,184)"}
                      width={"60px"}
                      height={"60px"}
                    />
                    <Text>
                      No Images yet. Click on the button to add the first one.
                    </Text>
                  </NoEntries>
                )}
                {provided.placeholder}
                <Button
                  onClick={addOneToTheList}
                  type={"button"}
                  adjustPosition={(images || []).length > 3}
                >
                  +
                </Button>
                <Snackbar
                  open={showSnackbar.open}
                  message={showSnackbar.message}
                  title="Duplicated Image"
                  status="warning"
                  autoHideDuration={3000}
                  onClose={() => {
                    setShowSnackbar({
                      open: false,
                      message: "",
                    });
                  }}
                ></Snackbar>
              </List>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Wrapper>
  );
};

export default MultipleImagesUploader;
