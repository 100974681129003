export const styles = {
  baseStyle: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "120px",
    minHeight: "120px",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#E0E0E0",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    width: "100%",
    cursor: "pointer",
    maxWidth: "768px",
    marginTop: "12px",
  },
  activeStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#FF3E1D",
    backgroundColor: "rgba(255, 62, 29, 0.05)",
  },
};

export const messages = {
  defaultMessage:
    "Drag a <b> image </b> here or <b> click </b> to upload one. <br /> (Max of <b> 10 images </b> per upload)",
  dragActiveMessage: "Drop the files here ...",
  uploadErrorMessage:
    "Something went wrong and the image might've not been uploaded",
  rejectedMessage:
    "There is something wrong with one of the files. Make sure it's an image and is lower than 2MB",
};
