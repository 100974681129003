import React, { useEffect } from "react";
import moment from "moment";
import { Launch, Download } from "@icons/";
import { getThumbnailUrl } from "@utils/misc";
import { useSetState } from "@hooks/";
import PropTypes from "prop-types";
import request from "@utils/request";

import {
  Backdrop,
  Modal,
  Wrapper,
  Frame,
  Form,
  Input,
  Select,
  Label,
  Text,
  TextArea,
  TextWrapper,
  StyledButton,
  ButtonsWrapper,
  IconWrapper,
  ThumbnailFieldWrapper,
  AltTextWrapper,
} from "./Components";

const ImageModal = ({
  modalData = {},
  onHide = () => {},
  onSave = () => {},
  languages = [],
  onError = () => {},
}) => {
  const [state, setState] = useSetState({
    altTexts: null,
    selectedAltText: null,
    selectedLang: null,
    name: "",
    downloadableContent: null,
    triedDownload: false,
  });

  const {
    altTexts,
    selectedAltText,
    selectedLang,
    name,
    downloadableContent,
    triedDownload,
  } = state;

  useEffect(() => {
    const initialLanguage = languages && languages[0];
    const altTexts = modalData?.file?.altText;
    const selectedAltText = (altTexts || []).find(
      (altText) => altText?.language?._id === initialLanguage._id
    );

    setState({
      altTexts: modalData?.file?.altText,
      selectedLang: languages && languages[0],
      selectedAltText: selectedAltText,
      name: modalData?.file?.name,
    });
  }, [modalData?.file?.altText, languages, setState, modalData?.file?.name]);

  const selectAltText = ({ target: { value } }) => {
    const altTextFound = (altTexts || []).find((altText) => {
      const langId = altText?.language?._id;
      return langId === value;
    });

    setState({
      selectedAltText: altTextFound,
      selectedLang: { _id: value },
    });
  };

  const onUpdateAltText = ({ target: { value } }) => {
    let newAltTexts = altTexts ? [...altTexts] : [];
    const index = newAltTexts.findIndex((altText) => {
      return altText?.language?._id === selectedLang._id;
    });

    const valueToUpdate = {
      language: selectedLang,
      value: value,
    };

    if (index === -1) {
      newAltTexts.push(valueToUpdate);
    } else {
      newAltTexts[index] = valueToUpdate;
    }

    setState({
      selectedAltText: valueToUpdate,
      altTexts: newAltTexts,
    });
  };

  const onChangeName = ({ target: { value } }) => {
    setState({
      name: value,
    });
  };

  const reset = () => {
    setState({
      altTexts: null,
      selectedAltText: null,
      selectedLang: null,
    });
  };

  const onClose = () => {
    onHide && onHide();
    reset();
  };

  useEffect(() => {
    const downloadImage = async (fileId) => {
      try {
        if (!fileId) return;

        const {
          data: { downloadImage },
        } = await request(
          `query {
            downloadImage (_id: "${fileId}"){
              content
            }
          }
          `
        );

        const content = Buffer.from(downloadImage.content?.data);

        setState({
          downloadableContent: `data:image/jpeg;base64,${content.toString(
            "base64"
          )}`,
        });
      } catch (e) {
        onError(e);
        setState({
          downloadableContent: null,
          triedDownload: true,
        });
      }
    };

    if (modalData.file && !triedDownload) {
      downloadImage(modalData.file._id);
    } else {
      setState({
        downloadableContent: null,
      });
    }
  }, [modalData.file, setState, onError, triedDownload]);

  const onSubmit = (e) => {
    try {
      onSave &&
        onSave({
          ...modalData.file,
          name: name,
          _id: modalData.file._id,
          altText: (altTexts || []).filter((altText) => altText !== null),
        });
      reset();
    } catch (e) {
      onError(e);
      console.error("e", e);
    }
  };

  return (
    <Backdrop show={modalData.show}>
      <Modal animation={false}>
        {modalData.file && (
          <Wrapper>
            <Frame src={modalData.file.url} />
            <IconWrapper
              href={modalData.file.url}
              target={"_blank"}
              rel="noreferrer noopener"
              active
            >
              <Launch width={"24px"} height={"24px"} />
            </IconWrapper>
            <IconWrapper
              order="first"
              href={!!downloadableContent ? downloadableContent : "#"}
              download={!!downloadableContent ? name : null}
              active={!!downloadableContent}
            >
              <Download width={"24px"} height={"24px"} />
            </IconWrapper>
            <Form onSubmit={onSubmit} id="image-modal-form">
              <TextArea>
                <TextWrapper>
                  <Text label={"true"}>{"Media type"}</Text>
                  <Text>{(modalData.file.ext || "--").toUpperCase()}</Text>
                </TextWrapper>
                <TextWrapper>
                  <Text label={"true"}>{"Media size"}</Text>
                  <Text>
                    {((modalData.file.size || 0) / 1024).toFixed(1) || "--"} KB
                  </Text>
                </TextWrapper>
                <TextWrapper>
                  <Text label={"true"}>{"Date"}</Text>
                  <Text>
                    {(modalData.file.createdAt &&
                      moment(modalData.file.createdAt).format("YYYY-MM-DD")) ||
                      "--"}
                  </Text>
                </TextWrapper>
                <TextWrapper>
                  <Text label={"true"}>{"Thumbnail"}</Text>
                  <ThumbnailFieldWrapper>
                    <Text style={{ marginBottom: "0" }}>
                      {modalData.file?.hasThumbnail ? "True" : "--"}
                    </Text>
                    {modalData.file?.hasThumbnail && (
                      <a
                        href={getThumbnailUrl(modalData.file)}
                        target={"_blank"}
                        rel="noreferrer noopener"
                        style={{ display: "flex" }}
                      >
                        <Launch width={"16px"} height={"16px"} />
                      </a>
                    )}
                  </ThumbnailFieldWrapper>
                </TextWrapper>
              </TextArea>
              <Label htmlFor="fileName-input"> Name </Label>
              <Input
                id={"fileName-input"}
                type={"text"}
                name={"name"}
                value={name || ""}
                placeholder={"Insert the image name"}
                onChange={onChangeName}
              />
              <Label htmlFor="altText-input"> Alternative Text </Label>
              <AltTextWrapper>
                <Select
                  onChange={selectAltText}
                  defaultValue={selectedLang?._id}
                >
                  {languages.map((lang) => {
                    return (
                      <option key={lang._id} value={lang._id}>
                        {lang.code}
                      </option>
                    );
                  })}
                </Select>
                <Input
                  id={"altText-input"}
                  type={"text"}
                  name={"altText"}
                  value={selectedAltText?.value || ""}
                  placeholder={"Insert an alternative text"}
                  onChange={onUpdateAltText}
                />
              </AltTextWrapper>
              <ButtonsWrapper>
                <StyledButton type="button" onClick={onClose}>
                  Cancel
                </StyledButton>
                <StyledButton type="submit" onClick={onSubmit}>
                  Update
                </StyledButton>
              </ButtonsWrapper>
            </Form>
          </Wrapper>
        )}
      </Modal>
    </Backdrop>
  );
};

ImageModal.propTypes = {
  modalData: PropTypes.object,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      _id: PropTypes.string,
    })
  ),
};

export default ImageModal;
