import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";

const LocationForm = ({ data, updateData }) => {
  return (
    <FormBuilder
      fields={[
        {
          name: "internalName",
          label: "Internal name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. Only used for internal control.",
        },
        {
          name: "name",
          label: "Location name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. UI representation of the location.",
        },
        {
          name: "slug",
          label: "Slug",
          half: true,
          type: "slug",
          required: true,
          information: "Required field. A unique identifier of the location.",
        },
        {
          name: "county",
          label: "Location's county",
          type: "relation",
          half: true,
          required: true,
          collection: "counties",
          information: "The county to where the location belongs",
        },
        {
          name: "image",
          label: "Location image",
          half: true,
          type: "image",
          information: "A representative image of the location.",
        },
      ]}
      data={data}
      updateData={updateData}
    />
  );
};

export default LocationForm;

export const gqlQuery = `
  query {
    internalName
    name
    slug
    image{
      url,
      _id
    }
    county {
      internalName,
      _id
    }
    signature {
      name,
      date
    }
    _id
  }
`;
