import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
`;

const Title = styled.h1`
  color: #424242;
  opacity: 0.5;
  font-size: 32px;
  text-align: center;
  margin-bottom: 16px;
`;

const Text = styled.p`
  color: #424242;
  font-size: 16px;
  text-align: center;
`;

export { Wrapper, Title, Text };
