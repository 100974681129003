import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Filter } from "@icons";
import { useSetState } from "@hooks";

import {
  Wrapper,
  IconWrapper,
  IconLabel,
  Select,
  Input,
  CloseFilters,
  ApplyFilters,
} from "./Components";

const operatorsForTypes = {
  Number: [
    { label: "is", operator: "eq" },
    { label: "greater than or equal to", operator: "gte" },
    { label: "lower than or equal to", operator: "lte" },
  ],
  String: [
    { label: "is", operator: "eq" },
    { label: "is not", operator: "ne" },
    { label: "contains", operator: "regex" },
  ],
  Boolean: [
    { label: "is", operator: "eq" },
    { label: "is not", operator: "ne" },
  ],
};

const transformFirstLetter = (string, action = "capitalize") => {
  let letters = string.split("");
  const firstChar = letters[0];

  let capitalizedFirstChar = "";

  if (action === "capitalize") {
    capitalizedFirstChar = firstChar.toUpperCase();
  } else if (action === "lowercase") {
    capitalizedFirstChar = firstChar.toLowerCase();
  } else {
    capitalizedFirstChar = firstChar;
  }
  letters[0] = capitalizedFirstChar;
  return letters.join("");
};

const extractItemSelected = (defaultFilter, keys) => {
  if (!defaultFilter) return 0;
  const [defaultKey] = defaultFilter.split(".");
  const index = keys.findIndex(
    (key) => key === transformFirstLetter(defaultKey)
  );
  return index;
};

const extractTypeSelected = (defaultFilter, keys, types) => {
  if (!defaultFilter) return 0;

  const itemSelected = extractItemSelected(defaultFilter, keys);

  const filterParams = defaultFilter.split(".");
  const defaultOperator = filterParams[1];

  const type = types[itemSelected];

  const typeOperatorValues = operatorsForTypes[type];

  const index = typeOperatorValues.findIndex(
    (typeOperator) => typeOperator.operator === defaultOperator
  );

  return index;
};

const extractSearchSelected = (defaultFilter) => {
  const filterParams = defaultFilter.split(".");
  return filterParams[2];
};

const EntityFilterBar = ({
  handleFilter = () => {},
  schema = {},
  defaultFilter = "",
}) => {
  const [keys, types] = useMemo(() => {
    return [
      Object.keys(schema).map((v) => transformFirstLetter(v)),
      Object.values(schema),
    ];
  }, [schema]);

  const [state, setState] = useSetState({
    showFields: !!defaultFilter,
    itemSelected: extractItemSelected(defaultFilter, keys),
    typeSelected: extractTypeSelected(defaultFilter, keys, types),
    search: extractSearchSelected(defaultFilter),
  });

  const { showFields, itemSelected, typeSelected, search } = state;

  const type = types[itemSelected];
  const typeOperatorValues = operatorsForTypes[type];
  const typeOperator = typeOperatorValues[typeSelected];

  const resetFilters = () => handleFilter();

  const displayFields = () => {
    resetFilters();
    setState({
      showFields: !showFields,
      itemSelected: 0,
      typeSelected: 0,
      search: "",
    });
  };

  const onSelectItem = useCallback(
    ({ target: { value } }) => {
      const index = keys.findIndex((key) => key === value);

      setState({
        itemSelected: index,
        typeSelected: 0,
        search: "",
      });
    },
    [keys, setState]
  );

  const onSelectOperator = ({ target: { value } }) => {
    const index = typeOperatorValues.findIndex(
      (typeOperator) => typeOperator.operator === value
    );
    setState({ typeSelected: index });
  };

  const onSearch = ({ target: { value } }) => {
    setState({ search: value });
  };

  const onApplyFilters = () => {
    handleFilter(
      keys[itemSelected] === "RNT"
        ? keys[itemSelected]
        : transformFirstLetter(keys[itemSelected], "lowercase"),
      typeOperator.operator,
      search
    );
  };

  return (
    <Wrapper>
      {showFields ? (
        <>
          <CloseFilters onClick={displayFields}> x </CloseFilters>
          <Select value={keys[itemSelected]} onChange={onSelectItem}>
            {keys.map((key) => (
              <option value={key} key={key}>
                {key}
              </option>
            ))}
          </Select>
          <Select value={typeOperator.operator} onChange={onSelectOperator}>
            {typeOperatorValues.map((typeOperator) => (
              <option value={typeOperator.operator} key={typeOperator.operator}>
                {typeOperator.label}
              </option>
            ))}
          </Select>
          {type === "Boolean" ? (
            <Select onChange={onSearch} value={search}>
              <option value=""></option>
              <option value="true">true</option>
              <option value="false">false</option>
            </Select>
          ) : (
            <Input
              defaultValue={search}
              key={keys[itemSelected]}
              onChange={onSearch}
              type={type === "String" ? "text" : "number"}
              placeholder={type === "String" ? "text" : "number"}
            />
          )}
          <ApplyFilters onClick={onApplyFilters} type="button">
            Apply
          </ApplyFilters>
        </>
      ) : (
        <IconWrapper onClick={displayFields}>
          <Filter />
          <IconLabel>Filter</IconLabel>
        </IconWrapper>
      )}
    </Wrapper>
  );
};

EntityFilterBar.propTypes = {
  handleFilter: PropTypes.func,
  schema: PropTypes.object,
  defaultFilter: PropTypes.string,
};

export default EntityFilterBar;
