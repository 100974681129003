import React, { useCallback } from "react";
import { Prev, Next } from "@icons";
import { Item, Pagination, ThreeDots, ArrowWrapper } from "./Components";
import PropTypes from "prop-types";

const GalleryPagination = ({
  nrOfPages = 0,
  pageIndex = 1,
  onPagination = () => {},
}) => {
  const currentPage = Math.min(pageIndex, nrOfPages);

  const onClick = useCallback(
    (selectedIndex) => {
      let indexToReturn = Math.max(selectedIndex, 1);
      indexToReturn = Math.min(indexToReturn, nrOfPages);
      onPagination && onPagination(indexToReturn);
    },
    [nrOfPages, onPagination]
  );

  const buildPaginationItems = (nrOfPages) => {
    const items = [];
    if (nrOfPages <= 3) {
      for (let i = 0; i < nrOfPages; i++) {
        items.push(
          <Item
            active={i + 1 === currentPage}
            onClick={() => onClick(i + 1)}
            key={"gallery-pagination-item-" + i}
          >
            {i + 1}
          </Item>
        );
      }
    } else if (currentPage === 1) {
      items.push(
        <Item active key={"gallery-pagination-item-" + currentPage}>
          {currentPage}
        </Item>
      );
      items.push(
        <Item
          onClick={() => onClick(currentPage + 1)}
          key={"gallery-pagination-item-" + (currentPage + 1)}
        >
          {currentPage + 1}
        </Item>
      );
      items.push(
        <Item
          onClick={() => onClick(currentPage + 2)}
          key={"gallery-pagination-item-" + (currentPage + 2)}
        >
          {currentPage + 2}
        </Item>
      );
    } else if (currentPage === nrOfPages) {
      items.push(
        <Item
          onClick={() => onClick(currentPage - 2)}
          key={"gallery-pagination-item-" + (currentPage - 2)}
        >
          {currentPage - 2}
        </Item>
      );
      items.push(
        <Item
          onClick={() => onClick(currentPage - 1)}
          key={"gallery-pagination-item-" + (currentPage - 1)}
        >
          {currentPage - 1}
        </Item>
      );
      items.push(
        <Item active key={"gallery-pagination-item-" + currentPage}>
          {currentPage}
        </Item>
      );
    } else {
      items.push(
        <Item
          onClick={() => onClick(currentPage - 1)}
          key={"gallery-pagination-item-" + (currentPage - 1)}
        >
          {currentPage - 1}
        </Item>
      );
      items.push(
        <Item active key={"gallery-pagination-item-" + currentPage}>
          {currentPage}
        </Item>
      );
      items.push(
        <Item
          onClick={() => onClick(currentPage + 1)}
          key={"gallery-pagination-item-" + (currentPage + 1)}
        >
          {currentPage + 1}
        </Item>
      );
    }

    return items;
  };

  const paginationItems = buildPaginationItems(nrOfPages);

  return (
    nrOfPages > 0 && (
      <Pagination>
        <div disabled={nrOfPages <= 3} onClick={() => onClick(1)} />
        <ArrowWrapper
          disabled={nrOfPages <= 3}
          onClick={() => onClick(currentPage - 1)}
        >
          <Prev width={"8px"} height={"8px"} />
        </ArrowWrapper>
        {nrOfPages > 3 && currentPage >= 3 && <ThreeDots />}
        {paginationItems}
        {nrOfPages > 3 && currentPage < nrOfPages - 1 && <ThreeDots />}
        <ArrowWrapper
          disabled={nrOfPages <= 3}
          onClick={() => onClick(currentPage + 1)}
        >
          <Next width={"8px"} height={"8px"} />
        </ArrowWrapper>
        <div disabled={nrOfPages <= 3} onClick={() => onClick(nrOfPages)} />
      </Pagination>
    )
  );
};

GalleryPagination.propTypes = {
  nrOfPages: PropTypes.number,
  pageIndex: PropTypes.number,
  onPagination: PropTypes.func,
};

export default GalleryPagination;
