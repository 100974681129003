import { useReducer } from "react";

const reducer = (state, newState) => ({ ...state, ...newState });

const useSetState = (initialState) => {
  const [state, setState] = useReducer(reducer, initialState);

  return [state, setState];
};

export default useSetState;
