import React from "react";
import { Container, Bar, Strength } from "./Components";
import PropTypes from "prop-types";

const scoreConverter = {
  0: "Forbidden",
  1: "Too weak",
  2: "Weak",
  3: "Medium",
  4: "Strong",
};

const PasswordStrength = ({ strength = 0 }) => {
  return (
    <>
      <Container>
        <Bar shouldHighlight={strength >= 1} strength={strength} />
        <Bar shouldHighlight={strength >= 2} strength={strength} />
        <Bar shouldHighlight={strength >= 3} strength={strength} />
        <Bar shouldHighlight={strength >= 4} color={strength} />
      </Container>
      <Strength>Status: {scoreConverter[strength]}</Strength>
    </>
  );
};

PasswordStrength.propTypes = {
  strength: PropTypes.number,
};

export default PasswordStrength;
