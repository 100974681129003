import React, { useCallback, useMemo, useEffect } from "react";
import { pages } from "@config/navigation/";
import NotFound from "@components/NotFound/NotFound";
import FormLayout from "@layouts/FormLayout/FormLayout";
import { useCapitalizedName, useSetState } from "@hooks";
import request from "@utils/request";
import { parseGqlQuery, extractErrorMessage } from "@utils/misc";

const pagesComponents = pages.reduce((result, entity) => {
  const component = entity.component;
  const slug = entity.to.split("/").join("");
  const label = entity.label;
  const module = require(`./pages/${component}/${component}.jsx`);
  try {
    if (component)
      result[slug] = {
        component: module.default,
        label: label,
        query: parseGqlQuery(module.gqlQuery),
        componentName: entity.component,
      };
    return result;
  } catch (e) {
    result[slug] = null;
    return result;
  }
}, {});

const CMS = ({ match }) => {
  const { params } = match;
  const title = useCapitalizedName(params["page"], false);
  const contentToRender = params["page"];

  const Page = useMemo(
    () => pagesComponents[contentToRender]?.component || null,
    [contentToRender]
  );

  const label =
    useMemo(() => pagesComponents[contentToRender]?.label, [contentToRender]) ||
    title;

  const componentName = useMemo(
    () => pagesComponents[contentToRender]?.componentName,
    [contentToRender]
  );

  const [state, setState] = useSetState({
    data: {},
    loading: true,
    gqlQuery: pagesComponents[contentToRender].query,
    fetched: false,
    saving: false,
    submitted: {
      status: false,
      error: false,
      message: "",
    },
  });

  useEffect(() => {
    const getData = async () => {
      const fetched = state.fetched;
      const gqlQuery = state.gqlQuery;
      const queryName = componentName.toLowerCase();

      const getQuery = `
        query {
          ${queryName}
          ${gqlQuery}
        }
      `;

      if (!fetched && getQuery) {
        try {
          const { data } = await request(getQuery);

          setState({
            data: data[queryName] || {},
            fetched: true,
            loading: false,
          });
        } catch (e) {
          console.error(e);
          setState({ fetched: true, loading: false });
        }
      }
    };

    getData();
  }, [setState, state.fetched, state.gqlQuery, title, componentName]);

  const updateData = useCallback(
    (field, value) => {
      let newData = { ...state.data, [field]: value };
      setState({ data: newData });
    },
    [setState, state.data]
  );

  const onSave = async (e) => {
    try {
      e.preventDefault();

      setState({
        saving: true,
      });

      const gqlQuery = state.gqlQuery;

      const writeQuery = `
        mutation write ($input: ${componentName}Input!) {
          edit${componentName} (input: $input) 
          ${gqlQuery}
        }`;

      const dataToSave = { ...state.data };
      delete dataToSave["signature"];

      const { data } = await request(writeQuery, { input: dataToSave });

      setState({
        touched: false,
        saving: false,
        submitted: {
          status: true,
          error: false,
          message: `${label} edited sucessfully`,
        },
      });

      const pageData = data[`edit${componentName}`];

      return setState({ data: pageData });
    } catch (e) {
      console.error(e);
      const message = extractErrorMessage(e);
      setState({
        saving: false,
        submitted: {
          status: true,
          error: true,
          message: message,
        },
      });
    }
  };

  const onCloseSnackbar = () => {
    setState({
      submitted: {
        error: false,
        status: false,
        message: state.submitted.message,
      },
    });
  };

  const { data, saving, loading, submitted } = state;

  return Page ? (
    <FormLayout
      containerTitle={label}
      disabled={saving || loading}
      loading={loading}
      signature={data?.signature || null}
      actions={{
        remove: { canRemove: false },
        save: {
          canSave: true,
          buttonText: `Save ${label.toLowerCase()}`,
          onSave: onSave,
        },
      }}
      snackbar={{
        open: submitted.status,
        error: submitted.error,
        message: submitted.message,
        onClose: onCloseSnackbar,
      }}
    >
      <Page updateData={updateData} data={data} setState={setState} />
    </FormLayout>
  ) : (
    <NotFound />
  );
};

export default CMS;
