import styled from "styled-components";

const ButtonWrapper = styled.button`
  width: ${(props) => (props.size === "sm" ? "32px" : "52px")};
  height: ${(props) => (props.size === "sm" ? "16px" : "32px")};
  border-radius: ${(props) => (props.size === "sm" ? "8px" : "19px")}; // 8px
  background-color: ${(props) =>
    props.status === true ? "#00d200" : "#E0E0E0"};
  padding: 3px;
  position: relative;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s linear;
  display: flex;
  align-items: center;

  &:active,
  :visited,
  :focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const Ellipse = styled.div`
  background: #ffffff;
  height: ${(props) => (props.size === "sm" ? "10px" : "26px")};
  width: ${(props) => (props.size === "sm" ? "10px" : "26px")};
  border-radius: 26px;
  position: absolute;
  left: ${(props) => {
    return props.status === true
      ? props.size === "sm"
        ? "18px"
        : "23px"
      : "3px";
  }};
  top: 3px;
  transition: left 0.2s linear;
`;

const Result = styled.span`
  margin-left: ${(props) => (props.size === "sm" ? "34px" : "56px")};
  font-size: ${(props) => (props.size === "sm" ? "10px" : "12px")};
  line-height: 16px;
  font-weight: 600;
`;

export { ButtonWrapper, Ellipse, Result };
