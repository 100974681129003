import React, { useRef, useEffect } from "react";
import Loader from "react-loader-spinner";
import BooleanButton from "../BooleanButton/BooleanButton";
import { useCapitalizedName, useSetState } from "@hooks";
import request from "@utils/request/";
import {
  Wrapper,
  Container,
  InputWrapper,
  ListWrapper,
  List,
  ListItem,
  LoadWrapper,
  NoResult,
  Input,
  Chip,
  RemoveButton,
  HiddenInput,
  InnerContainer,
  ToggleButtonWrapper,
  ReorderLabel,
} from "./Components";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DnDMultipleRelations = ({
  collection,
  onChangeSelection,
  value,
  required,
  debounceTime = 400,
  ...inputProps
}) => {
  const [state, setState] = useSetState({
    items: null,
    selectedItems: value || [],
    searchValue: value?.internalName || "",
    loading: false,
    setTimeoutId: null,
    listOpen: false,
    focusedChip: null,
    updatedOnMount: false,
    isDragging: false,
  });

  const inputRef = useRef(null);
  const hiddenInputRef = useRef(null);

  const {
    items,
    searchValue,
    selectedItems,
    loading,
    setTimeoutId,
    listOpen,
    focusedChip,
    updatedOnMount,
    isDragging,
  } = state;

  useEffect(() => {
    if (!updatedOnMount && value !== undefined) {
      setState({
        selectedItems: value,
        updatedOnMount: true,
        searchValue: value?.internalName || "",
      });
    }
  }, [value, updatedOnMount, setState]);

  const collectionName = useCapitalizedName(collection);
  const queryName = ` all${collectionName}`;

  const focusInput = (which) => {
    switch (which) {
      case "hidden": {
        hiddenInputRef?.current?.focus();
        break;
      }
      default: {
        inputRef?.current?.focus();
        setState({
          focusedChip: null,
        });
      }
    }
  };

  const blurInput = (which) => {
    switch (which) {
      case "hidden": {
        hiddenInputRef?.current?.blur();
        break;
      }
      default: {
        inputRef?.current?.blur();
      }
    }
  };

  const onSelectItem = (item) => {
    const newSelectedItems = [...(selectedItems || [])];
    newSelectedItems.push(item);
    setState({
      selectedItems: newSelectedItems,
      listOpen: false,
      searchValue: "",
    });
    onChangeSelection && onChangeSelection(newSelectedItems);
  };

  const onRemoveItem = (index) => {
    const newSelectedItems = [...(selectedItems || [])];
    newSelectedItems.splice(index, 1);
    setState({
      selectedItems: newSelectedItems,
      focusedChip: null,
    });
    onChangeSelection && onChangeSelection(newSelectedItems);
  };

  const onChange = ({ target: { value } }) => {
    clearTimeout(setTimeoutId);

    setState({
      loading: !!value,
      searchValue: value,
      listOpen: !!value,
      focusedChip: null,
    });

    if (!value) return;

    const ID = setTimeout(async () => {
      try {
        const selectedItemsIds = (selectedItems || []).map((i) => i._id);

        const { data } = await request(`
          query {
            ${queryName}(
              input: {
                page: 1,
                limit: 12,
                search: "${value}"
                exclude: ${JSON.stringify(selectedItemsIds)}
              }
            ) {
              items {
                _id,
                internalName
                slug
              }
            }
          }
        `);

        const { items } = data[queryName.trim()];

        setState({
          items: items,
          loading: false,
        });
      } catch (e) {
        console.error("e", e);
        setState({
          items: [],
          loading: false,
        });
      }
    }, debounceTime);

    setState({
      setTimeoutId: ID,
    });
  };

  const onHideList = () => {
    setState({
      listOpen: false,
    });
  };

  const focusChip = (index) => {
    setState({
      focusedChip: index,
    });
    focusInput("hidden");
    blurInput();
  };

  const onKeyDown = ({ key }) => {
    switch (key) {
      case "Backspace": {
        const nrOfSelectedItems = (selectedItems || []).length;
        if (!searchValue && nrOfSelectedItems > 0) {
          if (focusedChip === null) {
            focusChip(nrOfSelectedItems - 1);
          } else if (focusedChip === nrOfSelectedItems - 1) {
            onRemoveItem(nrOfSelectedItems - 1);
          }
        }
        break;
      }
      default: {
        // no-op
      }
    }
  };

  const onBlur = () => {
    setState({
      focusedChip: null,
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedItems,
      result.source.index,
      result.destination.index
    );

    setState({
      selectedItems: items,
    });

    onChangeSelection && onChangeSelection(items);
  };

  const onDrag = (value) => {
    setState({ isDragging: value });
  };

  const nrOfSelectedItems = (selectedItems || []).length;

  return (
    <Wrapper onClick={focusInput}>
      {listOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          onClick={onHideList}
        />
      )}
      <ToggleButtonWrapper>
        <ReorderLabel htmlFor="reorder-button"> Reorder: </ReorderLabel>
        <BooleanButton
          value={isDragging}
          onChange={() => onDrag(!isDragging)}
          size="sm"
          id={"reorder-button"}
        />
      </ToggleButtonWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Container isDragging={isDragging}>
          <Droppable
            droppableId={inputProps.id || `${collection}-droppable`}
            direction="vertical"
            isDropDisabled={!isDragging}
          >
            {(provided) => {
              return (
                <InnerContainer
                  isDragging={isDragging}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {(selectedItems || []).map((item, index) => {
                    return (
                      <Draggable
                        key={item?._id}
                        draggableId={"dragable-id-" + item?._id}
                        index={index}
                        isDragDisabled={!isDragging}
                      >
                        {(provided) => (
                          <Chip
                            key={`chip-${item?._id}-${index}`}
                            focused={index === focusedChip}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {item.internalName}
                            {!isDragging && (
                              <RemoveButton
                                onClick={() => onRemoveItem(index)}
                                type="button"
                              >
                                X
                              </RemoveButton>
                            )}
                          </Chip>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                  {!isDragging && (
                    <InputWrapper>
                      <Input
                        {...inputProps}
                        required={nrOfSelectedItems > 0 ? false : required}
                        value={searchValue}
                        onChange={onChange}
                        type="text"
                        ref={inputRef}
                        autoComplete="off"
                        onKeyDown={onKeyDown}
                        onBlur={onBlur}
                        placeholder={
                          nrOfSelectedItems > 0
                            ? ""
                            : `Search for ${collection}`
                        }
                      />
                    </InputWrapper>
                  )}
                </InnerContainer>
              );
            }}
          </Droppable>
          <HiddenInput
            ref={hiddenInputRef}
            onKeyDown={onKeyDown}
            defaultValue=""
            onChange={focusInput}
            onBlur={onBlur}
          />
        </Container>
      </DragDropContext>
      {listOpen ? (
        <ListWrapper>
          {loading ? (
            <LoadWrapper>
              <Loader
                type="TailSpin"
                width="24px"
                height="24px"
                color="#2aace2"
              />
            </LoadWrapper>
          ) : (
            <div>
              {items.length === 0 ? (
                <NoResult>Couldn't find anything</NoResult>
              ) : (
                <List>
                  {items.map((item) => (
                    <ListItem key={item._id} onClick={() => onSelectItem(item)}>
                      {item.internalName}
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          )}
        </ListWrapper>
      ) : null}
    </Wrapper>
  );
};

export default DnDMultipleRelations;
