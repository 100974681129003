import React, { useEffect } from "react";
import { Input } from "./Components";

import { useSetState } from "../../hooks";

const PhoneInput = ({ onFormat, defaultValue, prefix, ...inputProps }) => {
  const [state, setState] = useSetState({
    value: defaultValue || "",
    updatedOnMount: false,
  });

  const { value, updatedOnMount } = state;

  useEffect(() => {
    if (!updatedOnMount && defaultValue !== undefined) {
      setState({ value: defaultValue, updatedOnMount: true });
    }
  }, [defaultValue, updatedOnMount, setState]);

  const onBlur = () => {
    let formatedValue = value;

    if (prefix && !formatedValue.includes(prefix)) {
      formatedValue = `${prefix || ""} ${value}`;
    }

    setState({
      value: formatedValue,
    });

    onFormat && onFormat(formatedValue);
  };

  return (
    <Input
      value={value}
      onChange={({ target: { value } }) => setState({ value })}
      onBlur={onBlur}
      {...inputProps}
    />
  );
};

export default PhoneInput;
