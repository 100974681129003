import styled from "styled-components";

const Container = styled.div`
  background: ${(props) => props.color};
  border: 1px solid rgba(224, 224, 224, 0.15);
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 13px;
  position: fixed;
  right: ${(props) => (props.open ? "24px" : "-350px")};
  bottom: 24px;
  transition: all 0.2s ease-out;
  width: 100%;
  max-width: 320px;
  z-index: 5;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 9px;
  top: 9px;
  cursor: pointer;
`;

const MessageContainer = styled.div`
  padding-right: 60px;
  width: 100%;
`;

const Message = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => (props.status === "default" ? "#2c2c2c" : "#FFFFFF")};
  opacity: ${(props) => (props.status === "default" ? "0.5" : "1")};
  margin: 0;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: ${(props) => (props.status === "default" ? "#2c2c2c" : "#FFFFFF")};
  margin-top: 0;
  margin-bottom: 10px;
`;

export { Container, IconWrapper, MessageContainer, Title, Message };
