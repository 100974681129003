import React from "react";
import Quill from "../Quill/Quill";
import BooleanButton from "../BooleanButton/BooleanButton";
import ImageUploder from "../ImageUploader/ImageUploader";
import SingleRelation from "../SingleRelation/SingleRelation";
import MultipleRelations from "../MultipleRelations/MultipleRelations";
import SyncRelations from "../SyncRelations/SyncRelations";
import DnDMultipleRelations from "../DnDMultipleRelations/DnDMultipleRelations";
import SlugInput from "../SlugInput/SlugInput";
import PhoneInput from "../PhoneInput/PhoneInput";
import GalleryUploader from "../GalleryUploader/GalleryUploader";
import TinyEditor from "../TinyEditor/TinyEditor";
import {
  ElementWrapper,
  Label,
  Input,
  Textarea,
  Info,
  Link,
} from "./Components";
import PropTypes from "prop-types";

const FormBuilder = ({ fields = [], updateData = () => {}, data = {} }) => {
  return fields.map((field, index) => {
    const value = data[field.name];

    let formField = null;

    switch (field.type) {
      case "sync-relations": {
        formField = (
          <SyncRelations
            value={value}
            id={field.id || `${field.name}-${index}-${field.label}`}
            onChangeSelection={(items) => updateData(field.name, items)}
            collection={field.collection}
            required={field.required}
            relatedData={field.related}
            multi={field.multiple}
          />
        );
        break;
      }
      case "link": {
        formField = (
          <Link
            onClick={() => window.open(field.url, "_blank")}
            disabled={field.disabled}
          >
            {field.title}
          </Link>
        );
        break;
      }
      case "dnd-relations": {
        formField = (
          <DnDMultipleRelations
            value={value}
            id={field.id || `${field.name}-${index}-${field.label}`}
            onChangeSelection={(items) => updateData(field.name, items)}
            collection={field.collection}
            required={field.required}
            debounceTime={field.debounceTime}
          />
        );
        break;
      }
      case "phone": {
        formField = (
          <PhoneInput
            defaultValue={value}
            id={field.id || `${field.name}-${index}-${field.label}`}
            onFormat={(items) => updateData(field.name, items)}
            required={field.required}
            prefix={field.prefix}
            placeholder={field.placeholder || field.label}
          />
        );
        break;
      }
      case "texteditor": {
        formField = (
          <TinyEditor
            saveContent={(value) => updateData(field.name, value)}
            content={value || ""}
          />
        );
        break;
      }
      case "richtext": {
        formField = (
          <Quill
            onChange={(value) => updateData(field.name, value)}
            value={value || ""}
          />
        );
        break;
      }
      case "gallery": {
        formField = (
          <GalleryUploader
            initialImages={value}
            onImagesChange={(images) => updateData(field.name, images)}
          />
        );
        break;
      }
      case "slug": {
        formField = (
          <SlugInput
            defaultValue={value}
            id={field.id || `${field.name}-${index}-${field.label}`}
            onSlugify={(items) => updateData(field.name, items)}
            required={field.required}
          />
        );
        break;
      }
      case "relations": {
        formField = (
          <MultipleRelations
            value={value}
            id={field.id || `${field.name}-${index}-${field.label}`}
            onChangeSelection={(items) => updateData(field.name, items)}
            collection={field.collection}
            required={field.required}
            debounceTime={field.debounceTime}
            disabled={field.disabled}
          />
        );
        break;
      }
      case "relation": {
        formField = (
          <SingleRelation
            value={value}
            id={field.id || `${field.name}-${index}-${field.label}`}
            onSelect={(item) => updateData(field.name, item)}
            collection={field.collection}
            required={field.required}
            debounceTime={field.debounceTime}
            queryItems={field.queryItems}
            relatedData={field.items ? field.items : null}
          />
        );
        break;
      }
      case "image": {
        formField = (
          <ImageUploder
            key={value?._id}
            initialImage={value}
            onImageSelection={(image) => updateData(field.name, image)}
          />
        );
        break;
      }
      case "boolean": {
        formField = (
          <BooleanButton
            id={field.id || `${field.name}-${index}-${field.label}`}
            value={value || false}
            onChange={(value) => updateData(field.name, value)}
          />
        );
        break;
      }
      case "textarea": {
        formField = (
          <Textarea
            row={6}
            id={field.id || `${field.name}-${index}-${field.label}`}
            type={field.type}
            name={field.name}
            defaultValue={value}
            placeholder={field.placeholder || field.label}
            required={field.required}
            onChange={({ target: { value } }) => updateData(field.name, value)}
          />
        );
        break;
      }
      case "number": {
        formField = (
          <Input
            id={field.id || `${field.name}-${index}-${field.label}`}
            type={field.type}
            name={field.name}
            defaultValue={value !== undefined ? value : null}
            placeholder={field.placeholder || field.label}
            title={field.title}
            pattern={field.pattern}
            required={field.required}
            disabled={field.disabled}
            min={field.min}
            max={field.max}
            step={field.step}
            onWheel={(e) => e.target.blur()}
            onChange={({ target: { value } }) =>
              updateData(field.name, Number(value))
            }
          />
        );
        break;
      }
      default: {
        formField = (
          <Input
            id={field.id || `${field.name}-${index}-${field.label}`}
            type={field.type}
            name={field.name}
            defaultValue={value}
            placeholder={field.placeholder || field.label}
            title={field.title}
            pattern={field.pattern}
            required={field.required}
            disabled={field.disabled}
            maxLength={field.maxLength}
            onChange={({ target: { value } }) => updateData(field.name, value)}
          />
        );
      }
    }

    return field.hide ? null : (
      <ElementWrapper
        half={field.half}
        key={`${field.name}-${index}-${field.label}`}
        style={field?.style?.wrapper}
      >
        <Label htmlFor={field.id || `${field.name}-${index}-${field.label}`}>
          {field.label}
        </Label>
        {formField}
        {field.information ? (
          <Info type={field.type}>{field.information}</Info>
        ) : null}
      </ElementWrapper>
    );
  });
};

FormBuilder.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf([
        "textarea",
        "boolean",
        "image",
        "relation",
        "relations",
        "text",
        "number",
        "slug",
        "gallery",
        "email",
        "url",
        "password",
        "richtext",
        "texteditor",
        "phone",
        "dnd-relations",
        "sync-relations",
        "link",
      ]).isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.any,
      required: PropTypes.bool,
      disabled: PropTypes.bool,
      icon: PropTypes.node,
      information: PropTypes.string,
      title: PropTypes.string,
      half: PropTypes.bool,
      collection: PropTypes.string,
    })
  ).isRequired,
  updateData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default FormBuilder;
