import axios from "axios";
import { getToken } from "../token/";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${getToken()}`;
  return config;
});

const request = async (query, variables) => {
  const { data } = await instance.post("/graphql", { query, variables });

  return data;
};

export default request;
