import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

const TagForm = ({ updateData, data, setState }) => {
  const { translations = [], _id } = data;

  return (
    <>
      <FormBuilder
        fields={[
          {
            name: "internalName",
            type: "text",
            label: "Internal name",
            required: true,
            information: "Required field. Only used for internal control.",
            half: true,
          },
          {
            name: "slug",
            type: "slug",
            label: "Tag slug",
            required: true,
            information: "Required field. A unique identifier of the tag",
            half: true,
          },
          {
            name: "image",
            type: "image",
            label: "Tag Image",
            information: "Image to be used for experiences and campaigns",
            half: true,
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        translations={translations}
        updateData={updateData}
        setState={setState}
        parentId={_id}
        type="Tag"
        fields={[
          {
            name: "language",
            type: "relation",
            collection: "languages",
            label: "Language",
            half: true,
            information: "This is a required field",
            required: true,
          },
          {
            name: "title",
            type: "text",
            label: "Tag title",
            required: true,
            information: "Required field. UI representation of the tag.",
            half: true,
          },
          {
            name: "text",
            type: "textarea",
            label: "Tag text",
            information:
              "Optional. Support text for some use cases (ex: campaigns)",
            half: true,
          },
        ]}
      />
    </>
  );
};

export default TagForm;

export const gqlQuery = `
  query {
    _id
    internalName,
    slug,
    translations {
       _id,
       title,
       text,
       language {
         _id,
         internalName
       }
    }
    image {
      url,
      _id
    }
    signature {
      name,
      date
    }
  }
`;
