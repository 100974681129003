import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { AdminContext } from "@context/AdminContext";
import { services } from "@config/navigation";
import { Power, Avatar, Profile, ManageAccounts } from "@icons";
import { deleteToken, isSuperAdmin } from "@utils/token/";
import { buildUrl } from "@utils/misc/";

import {
  Wrapper,
  Menu,
  Item,
  StyledLink,
  Img,
  MenuHeader,
  Hello,
  Backdrop,
  AvatarWrapper,
  MenuTitle,
  IconWrapper,
  InnerWrapper,
  Header,
  Logo,
} from "./Components";

const BackofficeHeader = ({ path }) => {
  const { state, dispatch } = useContext(AdminContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const logout = () => {
    const response = window.confirm(
      "Are you sure you want to exit the dashboard?"
    );
    if (response === true) {
      deleteToken();
      dispatch("logout");
    }
  };

  const isSuperAdminRole = useMemo(isSuperAdmin, []);

  return (
    <Wrapper>
      <InnerWrapper>
        <Header to={buildUrl([path, "/"])}>
          <Logo src={"/images/C2P_logo_white.svg"} />
        </Header>
        <AvatarWrapper onClick={() => setMenuOpen(true)}>
          {state.admin.avatar ? (
            <Img src={state.admin.avatar.url} />
          ) : (
            <Avatar width="32px" height="32px" />
          )}
        </AvatarWrapper>
        {menuOpen && <Backdrop onClick={() => setMenuOpen(false)} />}
        <Menu open={menuOpen}>
          <MenuHeader>
            {state.admin.avatar ? (
              <Img src={state.admin.avatar.url} />
            ) : (
              <Avatar width="32px" height="32px" fill={"#363740"} />
            )}
            <Hello>{`${state.admin.username}`}</Hello>
          </MenuHeader>
          <MenuTitle> Settings </MenuTitle>
          <Item>
            <IconWrapper>
              <Profile width="18px" height="18px" fill="#2aace2" />
            </IconWrapper>
            <StyledLink
              to={buildUrl([path, "admins", "edit", state.admin._id])}
              onClick={() => setMenuOpen(false)}
            >
              Your Profile
            </StyledLink>
          </Item>
          {isSuperAdminRole && (
            <Item>
              <IconWrapper>
                <ManageAccounts width="18px" height="18px" fill="#2aace2" />
              </IconWrapper>
              <StyledLink
                to={buildUrl([path, "admins"])}
                onClick={() => setMenuOpen(false)}
              >
                Manage Administrators
              </StyledLink>
            </Item>
          )}
          <MenuTitle> Services </MenuTitle>
          {(services || []).map((service) => {
            const { key, icon: Icon, to, label } = service;

            return (
              <Item key={key}>
                <IconWrapper type="service">
                  <Icon width="18px" height="18px" fill="#5E81F4" />
                </IconWrapper>
                <StyledLink
                  to={buildUrl([path, to])}
                  onClick={() => setMenuOpen(false)}
                >
                  {label}
                </StyledLink>
              </Item>
            );
          })}
          <MenuTitle> See ya! </MenuTitle>
          <Item type="logout">
            <IconWrapper type="logout">
              <Power width={"18px"} height={"18px"} fill={"rgb(247, 91, 29)"} />
            </IconWrapper>
            <StyledLink to="/" type="logout" onClick={logout}>
              Logout
            </StyledLink>
          </Item>
        </Menu>
      </InnerWrapper>
    </Wrapper>
  );
};

BackofficeHeader.propTypes = {
  path: PropTypes.string.isRequired,
};

export default BackofficeHeader;
