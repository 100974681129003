import React from "react";
import { Wrapper, Divider, Title, Header, ContentWrapper } from "./Components";
import PropTypes from "prop-types";

const ContentPanel = ({ label, children }) => {
  return (
    <Wrapper>
      <Header>
        <Title>{label}</Title>
      </Header>
      <Divider />
      <ContentWrapper>{children}</ContentWrapper>
    </Wrapper>
  );
};

ContentPanel.propTypes = {
  label: PropTypes.node,
};

export default ContentPanel;
