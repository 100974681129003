import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { Editor } from "@tinymce/tinymce-react";
import isEmpty from "lodash/isEmpty";
import { Wrapper, LoaderWrapper } from "./Components";
// import { uploadImage } from "../../utils/apiRequests/";

const TinyEditor = ({ content, saveContent }) => {
  const [loading, setLoading] = useState(true);

  try {
    const isThereContent = (content) => !isEmpty(content);

    const onEditorInit = () => {
      setLoading(false);
    };

    return (
      <Wrapper>
        {loading ? (
          <LoaderWrapper>
            <Loader type="TailSpin" color="#00BFFF" height={60} width={60} />
          </LoaderWrapper>
        ) : null}
        <Editor
          initialValue={isThereContent(content) ? content : ""}
          onInit={onEditorInit}
          onBlur={(e, editor) =>
            saveContent && saveContent(editor.getContent())
          }
          init={{
            height: 1000,
            menubar: true,
            removed_menuitems: "newdocument",
            font_formats:
              "Circular=Circular std,sans-serif; Open Sans=Open Sans,sans-serif",
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap'); body { font-family: Open Sans, Sans-serif; }",
            plugins: [
              "advlist save autolink lists link",
              "charmap print preview anchor help",
              "searchreplace visualblocks",
              "insertdatetime media table wordcount toc fullscreen pagebreak",
            ],
            toolbar: `undo redo fullscreen | 
              formatselect fontselect fontsizeselect| 
              bold italic forecolor backcolor | 
              alignleft aligncenter alignright | 
              bullist numlist outdent indent pagebreak | 
              table`,
            toolbar_mode: "floating",
            contextmenu: "default",
            tinycomments_mode: "embedded",
            file_picker_types: "image",
            toc_depth: 3,
          }}
          apiKey={process.env.REACT_APP_TINYEDITOR_API_KEY}
        />
      </Wrapper>
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default TinyEditor;
