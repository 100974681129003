import React from "react";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

const Termspage = ({
  data = {},
  updateData = () => {},
  setState = () => {},
}) => {
  const { translations = [] } = data;

  return (
    <>
      <TranslationsPanel
        fields={[
          {
            type: "relation",
            name: "language",
            label: "Language",
            collection: "languages",
            half: true,
            information: "It's a required field",
            required: true,
          },
          {
            name: "content",
            type: "texteditor",
            label: "Page content",
            half: false,
          },
        ]}
        updateData={updateData}
        translations={translations}
        setState={setState}
        type="Termspage"
      />
    </>
  );
};

export default Termspage;

export const gqlQuery = `
  query {
    slug
    translations {
      language {
        _id,
        internalName,
        code
      }
      content
    }
    signature {
      name,
      date
    }
  }
`;
