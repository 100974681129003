import React, { useCallback } from "react";
import XLSX from "xlsx";
import Loader from "react-loader-spinner";
import { useDropzone } from "react-dropzone";
import io from "socket.io-client";

import { useSetState } from "@hooks";
import FormLayout from "@layouts/FormLayout/FormLayout";
import { Excel } from "@icons";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import Modal from "@components/Modal/Modal";
import Snackbar from "@components/Snackbar/Snackbar";

import { Dropzone, Text } from "./Components";
import request from "@utils/request";
import { validateEmail, validateURL } from "@utils/misc";

const ExportButtonContent = ({ isLoading }) => {
  return !isLoading ? (
    "Export accommodations"
  ) : (
    <Loader type="ThreeDots" color={"#fff"} height={"12px"} width={"150px"} />
  );
};

const excelHeadings = [
  "slug",
  "RNT",
  "c2pId",
  "name",
  "ahpchecked",
  "active",
  "website",
  "bookingUrl",
  "stars",
  "email",
  "phone",
  "tyId",
  "tyScore",
  "tyReviewsCount",
  "lng",
  "lat",
  "address",
  "pt",
  "en",
  "es",
  "fr",
  "de",
];

const isBoolean = (value) => value === true || value === false;
const isNumber = (value) => typeof value === "number" || !isNaN(Number(value));
const isEmail = (value) => validateEmail(value);
const isURL = (value) => validateURL(value);
const isEmpty = (value) =>
  value === undefined || value === "" || value === null;

const validationErrorMessage = (key, item) =>
  `<br/><br/><b>${key}</b> validation failed for hotel <b>${item["slug"]}</b> for value: <i>${item[key]}</i>`;

const API_URL = process.env.REACT_APP_API_URL;

const languagesOrder = { pt: 1, en: 2, es: 3, fr: 4, de: 5 };

const ImportExport = () => {
  const [state, setState] = useSetState({
    exportLoading: false,
    importLoading: false,
    overwriteData: false,
    itemsToUpload: null,
    invalidItems: null,
    invalidFieldsMessages: null,
    openOverwriteDataModal: false,
    openUploadModal: false,
    errorMessage: "",
    openSnackbar: false,
  });

  const {
    importLoading,
    exportLoading,
    openOverwriteDataModal,
    errorMessage,
    openUploadModal,
    invalidFieldsMessages,
    itemsToUpload,
    overwriteData,
    openSnackbar,
  } = state;

  const handleExport = async () => {
    try {
      setState({
        exportLoading: true,
      });

      const {
        data: { allAccommodations = {} },
      } = await request(`
        query {
          allAccommodations (input: {
            limit: 1000,
            page: 0
          }) {
            count,
            items {
              slug,
              RNT,
              name,
              c2pId,
              website,
              active,
              ahpchecked,
              email,
              phone,
              tyScore,
              tyId,
              stars,
              tyReviewsCount,
              lng,
              lat,
              address,
              translations {
                language {
                  code
                }
                description
              }
            }
          }
        }
      `);

      const ws_name = "Accommodations";

      const { items } = allAccommodations || {};

      const accommodationsRows = items.map((accommodation) => {
        const translationsRow = (accommodation?.translations || [])
          .sort((tA, tB) => {
            const languageA = tA?.language?.code;
            const languageB = tB?.language?.code;

            return languagesOrder[languageA] - languagesOrder[languageB];
          })
          .map((t) => t.description);

        return [
          accommodation.slug || "",
          accommodation.RNT || "",
          accommodation.c2pId || "",
          accommodation.name || "",
          accommodation.ahpchecked || false,
          accommodation.active || false,
          accommodation.website || "",
          accommodation.bookingUrl || "",
          accommodation.stars || "",
          accommodation.email || "",
          accommodation.phone || "",
          accommodation.tyId || "",
          accommodation.tyScore || "",
          accommodation.tyReviewsCount || "",
          accommodation.lng || "",
          accommodation.lat || "",
          accommodation.address || "",
          ...translationsRow,
        ];
      });

      const wb = XLSX.utils.book_new();

      const ws_data = [excelHeadings, ...accommodationsRows];

      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      XLSX.writeFile(wb, "c2p_accommodations.xlsx");

      setState({
        exportLoading: false,
      });
    } catch (e) {
      console.error(e);
      setState({
        exportLoading: false,
      });
    }
  };

  const uploadItems = useCallback(
    async (items) => {
      setState({
        openUploadModal: false,
      });

      const {
        data: { importAccommodations },
      } = await request(
        `
        mutation uploadAccommodations ($input: ImportInput!) {
          importAccommodations(input: $input) {
            ok,
            eventId
          }
        }
      `,
        { input: { overwrite: overwriteData, items: items } }
      );

      const { eventId } = importAccommodations;

      const socket = io(API_URL, {
        query: {
          roomId: eventId,
        },
      });

      return new Promise((resolve, reject) => {
        socket.on(eventId, (status) => {
          const { ok } = status;

          if (ok === true) {
            resolve({ ok });

            setState({
              importLoading: false,
              openSnackbar: ok,
            });
          } else {
            reject("Something went wrong");
          }

          socket.disconnect();
        });
      });
    },
    [setState, overwriteData]
  );

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      try {
        setState({
          importLoading: true,
        });

        const [file] = acceptedFiles;

        let reader = new FileReader();

        reader.readAsArrayBuffer(file);

        reader.onload = async function (e) {
          try {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, { type: "array" });
            const first_sheet_name = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[first_sheet_name];
            const excelData = XLSX.utils.sheet_to_json(worksheet);

            const validItems = [];
            const notValidFieldsMessages = [];
            let row = 1;

            if (excelData.length > 1000) {
              throw new Error("Max number of accommodations exceeded");
            }

            for (let item of excelData) {
              row++;

              const hotelWithRepeatedSlug = validItems.find(
                (validItem) => validItem.slug === item.slug
              );

              if (hotelWithRepeatedSlug) {
                throw new Error(
                  `Accommodation in row ${row} has a repeated slug ${hotelWithRepeatedSlug.slug}`
                );
              } else if (!item["slug"] || !item["name"]) {
                throw new Error(
                  `Accommodation in row ${row} does not have a slug or name`
                );
              } else {
                excelHeadings.forEach((key) => {
                  if (isEmpty(item[key])) {
                    delete item[key];
                  } else {
                    const value = item[key];
                    switch (key) {
                      case "website":
                      case "bookingUrl": {
                        const isUrlValid = isURL(value);
                        if (!isUrlValid) {
                          notValidFieldsMessages.push(
                            validationErrorMessage(key, item)
                          );
                          delete item[key];
                        }
                        break;
                      }
                      case "email": {
                        const isEmailValid = isEmail(value);
                        if (!isEmailValid) {
                          notValidFieldsMessages.push(
                            validationErrorMessage(key, item)
                          );
                          delete item[key];
                        }
                        break;
                      }
                      case "stars":
                      case "tyScore":
                      case "tyReviewsCount":
                      case "lng":
                      case "lat": {
                        const isValidNumber = isNumber(value);
                        if (!isValidNumber) {
                          notValidFieldsMessages.push(
                            validationErrorMessage(key, item)
                          );
                          delete item[key];
                        }
                        break;
                      }
                      case "ahpchecked":
                      case "active": {
                        const isValidBoolean = isBoolean(value);
                        if (!isValidBoolean) {
                          notValidFieldsMessages.push(
                            validationErrorMessage(key, item)
                          );
                          delete item[key];
                        }
                        break;
                      }
                      default: {
                        //no-op
                      }
                    }
                  }
                });
                validItems.push(item);
              }
            }

            if (validItems.length === 0) {
              throw new Error("No accommodations to upload");
            }

            if (notValidFieldsMessages.length > 0) {
              setState({
                openUploadModal: true,
                invalidFieldsMessages: notValidFieldsMessages,
                itemsToUpload: validItems,
              });
            } else {
              await uploadItems(validItems);
            }
          } catch (e) {
            console.error("e", e);
            setState({
              importLoading: false,
              errorMessage: typeof e === "string" ? e : e.message,
              openSnackbar: true,
            });
          }
        };
      } catch (e) {
        console.error("e", e);
        setState({
          importLoading: false,
          errorMessage: typeof e === "string" ? e : e.message,
          openSnackbar: true,
        });
      }
    },
    [setState, uploadItems]
  );

  const constructErrorMessage = (errors) => {
    try {
      let errorMessage = "";
      const max = Math.min(errors.length, 2);
      for (let i = 0; i < max; i++) {
        errorMessage =
          errorMessage +
          `<pre> Error importing file <i>${
            errors[i].file && errors[i].file.name
          }</i></pre> : ${
            errors[i].errors && errors[i].errors[0].message.toLowerCase()
          } <br/>`;
      }

      if (errors.length > 2) {
        errorMessage = errorMessage + "and more ...";
      }

      return errorMessage;
    } catch (e) {
      console.error("e", e);
      return "Something went wrong. Try again later.";
    }
  };

  const onDropRejected = useCallback(
    (errors) => {
      const errorMessage = constructErrorMessage(errors);
      setState({
        errorMessage: errorMessage,
      });
    },
    [setState]
  );

  const onDragEnter = () => {
    !importLoading &&
      setState({
        errorMessage: "",
      });
  };

  const onClickDropZone = () => {
    !importLoading &&
      setState({
        errorMessage: "",
      });
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    onDragEnter,
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    maxFiles: 1,
    enabled: !importLoading,
    maxSize: 1 * 1024 * 1024,
  });

  const { onClick, ...rootProps } = getRootProps();

  return (
    <FormLayout
      containerTitle={"Import/Export accommodations"}
      signature={null}
      disable={exportLoading || importLoading}
      actions={{
        remove: { canRemove: false },
        save: {
          canSave: true,
          buttonText: <ExportButtonContent isLoading={exportLoading} />,
          onSave: (e) => {
            e.preventDefault();
            handleExport();
          },
        },
      }}
    >
      <FormBuilder
        fields={[
          {
            type: "boolean",
            label: "Overwrite data?",
            information:
              "If true, data from the excel file will be saved over the database",
            name: "overwriteData",
          },
        ]}
        data={state}
        updateData={(_, value) => {
          if (value === true) {
            setState({ openOverwriteDataModal: true });
          } else {
            setState({ overwriteData: false });
          }
        }}
      />
      <Dropzone
        {...rootProps}
        isDragActive={isDragActive}
        isDragAccept={isDragAccept}
        isDragReject={isDragReject}
        onClick={(e) => {
          onClickDropZone();
          onClick(e);
        }}
      >
        {!importLoading && <Excel />}
        <input {...getInputProps()} />
        {importLoading ? (
          <>
            <Loader type="Rings" color="#2aace2" />
            <Text>
              {itemsToUpload
                ? `Uploading ${itemsToUpload.length} accommodations...`
                : "Uploading file..."}
            </Text>
          </>
        ) : isDragReject ? (
          <Text error={"true"}>
            Cannot accept this file. Make sure it's a Excel file with less than
            1MB
          </Text>
        ) : isDragActive ? (
          <Text> Drop the Excel here ...</Text>
        ) : (
          <Text>
            <>
              Drag a <b> excel file </b> here or <b> click </b> to upload one{" "}
              <br />
              Max number of <b>1000</b> accommodations
            </>
          </Text>
        )}
        <Text error dangerouslySetInnerHTML={{ __html: errorMessage }} />
      </Dropzone>
      <Modal
        open={openOverwriteDataModal}
        title={"Are you sure you want to overwrite DB data?"}
        body={`
          If set to true, all data inserted in the excel file will be saved over the existing data. 
          Once the excel file is upload, this action is irreversible. 
          If you still want to proceed, consider exporting an excel file as a backup with existing data before uploading a new one.
        `}
        onCancel={() =>
          setState({ overwriteData: false, openOverwriteDataModal: false })
        }
        onContinue={() =>
          setState({ overwriteData: true, openOverwriteDataModal: false })
        }
      />
      <Modal
        open={openUploadModal}
        title={`Are you sure you want to upload this file?`}
        body={`<b>${
          invalidFieldsMessages && invalidFieldsMessages.length
        } validation errors: </b>  ${invalidFieldsMessages}`}
        onCancel={() =>
          setState({
            itemsToUpload: null,
            openUploadModal: false,
            invalidFieldsMessages: null,
            importLoading: false,
          })
        }
        onContinue={async () => {
          try {
            await uploadItems(itemsToUpload);
          } catch (e) {
            console.error("e", e);
            setState({
              importLoading: false,
              errorMessage: "Something went wrong. Please, try again.",
              openSnackbar: true,
            });
          }
        }}
      />
      <Snackbar
        open={openSnackbar}
        title={errorMessage ? "Erro" : "Success"}
        message={
          errorMessage ? errorMessage : "Accommodations have been uploaded"
        }
        status={errorMessage ? "alert" : "success"}
        autoHideDuration={3000}
        onClose={() => setState({ openSnackbar: false })}
      />
    </FormLayout>
  );
};

export default ImportExport;
