import styled from "styled-components";

const Backdrop = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  visibility: ${(props) => (props.show ? "visibile" : "hidden")};
  align-items: center;
  justify-content: center;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.28);
  z-index: ${(props) => (props.show ? "4" : "hidden")};
`;

const Modal = styled.div`
  width: 100%;
  max-width: 768px;
  position: relative;
  z-index: 5;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: #e5e5e5;
  position: relative;
`;

const IconWrapper = styled.a`
  position: absolute;
  padding: 2px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(227, 233, 243);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  cursor: pointer;
  top: 8px;
  display: flex;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  left: ${(props) =>
    props.order === "first" ? "calc(50% - 68px)" : "calc(50% - 34px)"};

  &:hover {
    background-color: rgb(227, 233, 243);
  }
`;

const Frame = styled.div`
  width: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("${(props) => props.src}");
  height: 400px;
  border: 36px solid #e5e5e5;
  box-sizing: border-box;
`;

const ThumbnailFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 12.584px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const Select = styled.select`
  padding: 0px 8px;
  border: 1px solid #e0e0e0;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 2px);
  color: #495057;
  border-radius: 2px;
  line-height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  max-width: 75px;
  &::placeholder {
    line-height: calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0px 12px;
  }
`;

const AltTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Input = styled.input`
  padding: 8px 7px 8px 12px;
  border: 1px solid #e0e0e0;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 2px);
  color: #495057;
  border-radius: 2px;
  width: ${(props) =>
    props.name === "altText" ? "calc(100% - 80px)" : "auto"};
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 50%;
  padding: 24px;
`;

const TextArea = styled.div`
  width: 100%;
  padding: 12px;
  background-color: rgb(250, 250, 250);
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const TextWrapper = styled.span`
  width: calc(50% - 24px);
  margin: 0 12px;
`;

const Text = styled.p`
  margin: 0px;
  line-height: 18px;
  color: rgb(158, 167, 184);
  font-size: 13px;
  margin-bottom: ${(props) => (props.label === "true" ? "0" : "8px")};
  font-weight: ${(props) => (props.label === "true" ? "600" : "400")};
  text-transform: ${(props) =>
    props.label === "true" ? "capitalize" : "none"};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-top: auto;
  justify-content: space-between;
`;

const StyledButton = styled.button`
  outline: none;
  padding: 4px 16px;
  background-color: ${(props) =>
    props.type === "button" ? "#fff" : "#3751ff"};
  color: ${(props) => (props.type === "button" ? "#000" : "#fff")};
  border: ${(props) =>
    props.type === "button" ? "1px solid #000" : "1px solid #3751ff"};

  font-size: 13px;
  font-weight: 600;
  vertical-align: middle;
  border-radius: 5px;
  max-width: 240px;
  margin-left: ${(props) => (props.type === "button" ? "0" : "auto")};
  cursor: pointer;
  &:active,
  &:focus,
  &:visited {
    outline: none;
  }
`;

export {
  Modal,
  Backdrop,
  Wrapper,
  Frame,
  Input,
  Label,
  Form,
  TextArea,
  TextWrapper,
  Text,
  StyledButton,
  ButtonsWrapper,
  IconWrapper,
  Select,
  ThumbnailFieldWrapper,
  AltTextWrapper,
};
