import React from "react";

const Prev = ({ width = "8px", height = "14px", fill = "#2C2C2C " }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7.0001C0 7.3537 0.140481 7.6929 0.390523 7.9429L5.72392 13.2763C6.24459 13.797 7.08886 13.797 7.60953 13.2763C8.1302 12.7556 8.1302 11.9113 7.60953 11.3907L3.21896 7.0001L7.60953 2.60954C8.1302 2.08883 8.1302 1.24461 7.60953 0.723897C7.08886 0.2032 6.24459 0.2032 5.72392 0.723897L0.390523 6.05729C0.140481 6.30729 0 6.64649 0 7.0001Z"
        fill={fill}
      />
    </svg>
  );
};

export default Prev;
