import { useContext, useMemo } from "react";
import { AdminContext } from "../../context/AdminContext";
import { isSuperAdmin } from "../../utils/token";

const useAccess = (entity) => {
  const {
    state: { admin },
  } = useContext(AdminContext);

  const { access = {} } = admin;

  const accessData = useMemo(() => {
    const isSuper = isSuperAdmin();
    return {
      canRead: isSuper ? true : access["read"]?.includes(entity) || false,
      canDelete: isSuper ? true : access["delete"]?.includes(entity) || false,
      canUpdate: isSuper ? true : access["update"]?.includes(entity) || false,
      canCreate: isSuper ? true : access["create"]?.includes(entity) || false,
    };
  }, [access, entity]);

  return accessData;
};

export default useAccess;
