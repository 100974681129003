import styled from "styled-components";

const Backdrop = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.24);
  display: ${(props) => (props.open ? "flex" : "none")};
  z-index: ${(props) => (props.open ? "5" : "-1")};
  visibility: ${(props) => (props.open ? "visible" : "hidden")};
  opacity: ${(props) => (props.open ? "1" : "0")};
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  color: #fff;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #424242;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  max-height: calc(100% - 64px);
  flex-direction: column;
  max-width: 600px;
  border-radius: 4px;
`;

const Title = styled.h1`
  margin: 0;
  padding: 16px 24px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: 0.0075em;
  font-size: 16px;
  color: #fff;
`;

const Body = styled.div`
  padding: 8px 24px;
  overflow-y: auto;
  margin: 0 0 12px 0;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);

  & > * {
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

const Footer = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-end;
`;

const Button = styled.div`
  color: #90caf9;
  font-size: 14px;
  min-width: 64px;
  padding: 6px 8px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-weight: 600;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(144, 202, 249, 0.08);
  }
`;

export { ModalWrapper, Backdrop, Title, Body, Footer, Button };
