import React from "react";
import { Wrapper, Translation, OutterBorder } from "./Components";

const TableTranslations = ({ translations = [] }) => {
  const nrOfTranslations = (translations || []).length;

  return (
    <Wrapper>
      {(translations || []).slice(0, 3).map((translation, index) => {
        return (
          <OutterBorder key={`translations-table-${index}`} first={index === 0}>
            <Translation>{translation?.language?.code}</Translation>
          </OutterBorder>
        );
      })}
      {nrOfTranslations > 3 && (
        <OutterBorder key={`translations-table`}>
          <Translation>{nrOfTranslations - 3}+</Translation>
        </OutterBorder>
      )}
    </Wrapper>
  );
};

export default TableTranslations;
