import React from "react";
import { pages } from "@config/navigation/";
import { buildUrl } from "@utils/misc/";
import { StyledLink, Label, Wrapper } from "./Components";

const CMSPages = ({ location: { pathname } }) => {
  return (
    <Wrapper>
      {pages.map((page) => {
        const { label, to, icon: Icon, key } = page;
        const href = buildUrl([pathname, to]);
        return (
          <StyledLink to={href} key={key}>
            <Icon fill="#5E81F4" />
            <Label>{label}</Label>
          </StyledLink>
        );
      })}
    </Wrapper>
  );
};

export default CMSPages;
