import React, { useState } from "react";
import { Wrapper, Content, Label, ActionButton } from "./Components";
import PropTypes from "prop-types";

const ExpansionPanel = ({
  label,
  defaultOpen = false,
  children,
  action,
  style,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  const {
    handler = () => {},
    status = "default",
    actionLabel = "new",
  } = action || {};

  const onActionHandler = (e) => {
    e.stopPropagation();
    handler && handler();
  };

  return (
    <Wrapper open={open} style={style}>
      <Label onClick={() => setOpen((prev) => !prev)}>
        <span>{label}</span>
        {action && (
          <ActionButton onClick={onActionHandler} type="button" status={status}>
            {actionLabel}
          </ActionButton>
        )}
      </Label>
      <Content open={open}>{children}</Content>
    </Wrapper>
  );
};

ExpansionPanel.propTypes = {
  label: PropTypes.string.isRequired,
  defaultOpen: PropTypes.bool,
  children: PropTypes.node,
  action: PropTypes.shape({
    handler: PropTypes.func.isRequired,
    status: PropTypes.oneOf(["default", "alert"]).isRequired,
    actionLabel: PropTypes.string.isRequired,
  }),
  style: PropTypes.object,
};

export default ExpansionPanel;
