import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App.jsx";
import { BrowserRouter, Route } from "react-router-dom";
import { AdminProvider } from "@context/AdminContext";
import { GalleryProvider } from "@context/GalleryContext";

try {
  require("dotenv").config();
} catch (e) {
  console.error(e);
}

const root = document.getElementById("root");

export const wrappedApp = (
  <React.StrictMode>
    <AdminProvider>
      <GalleryProvider>
        <BrowserRouter>
          <Route path={"/"} render={(props) => <App {...props} />} />
        </BrowserRouter>
      </GalleryProvider>
    </AdminProvider>
  </React.StrictMode>
);

ReactDOM.render(wrappedApp, root);
