import { useMemo } from "react";
import pluralize from "pluralize";

const useCapitalizedName = (str = "", plural = true) => {
  const formattedName = useMemo(() => {
    const firstChar = str.charAt(0);
    const upperCaseFirstChar = firstChar.toUpperCase();
    const name = str.replace(firstChar, upperCaseFirstChar);

    return plural ? pluralize(name) : name;
  }, [str, plural]);

  return formattedName;
};

export default useCapitalizedName;
