import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
`;

const Bar = styled.div`
  background-color: ${(props) => {
    if (!props.shouldHighlight) {
      return "#b2aba9";
    } else {
      return props.strength === 1
        ? "#FF3E1D"
        : props.strength === 2
        ? "#FF9F8E"
        : props.strength === 3
        ? "#FFD422"
        : "#00D200";
    }
  }};
  height: 1px;
  width: calc(25% - 4px);
  margin: 0 2px;
`;

const Strength = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #2c2c2c;
  opacity: 0.5;
  text-align: right;
  margin: 4px 0 20px 0;
`;

export { Container, Bar, Strength };
