import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";

const CountyForm = ({ data, updateData }) => {
  return (
    <FormBuilder
      fields={[
        {
          name: "internalName",
          label: "Internal name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. Only used for internal control.",
        },
        {
          name: "name",
          label: "County name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. UI representation of the county.",
        },
        {
          name: "slug",
          label: "Slug",
          half: true,
          type: "slug",
          required: true,
          information: "Required field. A unique identifier of the county.",
        },
        {
          name: "locations",
          label: "Locations",
          type: "relations",
          half: true,
          collection: "locations",
          information: "List of locations that belong to this county.",
          disabled: true,
        },
        {
          name: "district",
          label: "County's district",
          type: "relation",
          half: true,
          required: true,
          collection: "districts",
          information: "The district to where the county belongs",
        },
        {
          name: "region",
          label: "County's region",
          type: "relation",
          required: true,
          half: true,
          collection: "regions",
          information: "The region to where the county belongs",
        },
        {
          name: "image",
          label: "County image",
          half: true,
          type: "image",
          information: "A representative image of the county.",
        },
      ]}
      data={data}
      updateData={updateData}
    />
  );
};

export default CountyForm;

export const gqlQuery = `
  query {
    internalName
    name
    slug
    image{
      url,
      _id
    }
    locations {
      internalName,
      _id
    }
    district {
      internalName,
      _id
    }
    region {
      internalName,
      _id
    }
    signature {
      name,
      date
    }
    _id
  }
`;
