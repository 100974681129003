import React, { createContext, useState } from "react";

export const GalleryContext = createContext();

export const GalleryProvider = ({ children }) => {
  const [state, setState] = useState({
    searchValue: "",
  });

  const reducer = (action, data) => {
    switch (action) {
      case "search":
        setState({
          searchValue: data,
        });
        break;

      default:
      //
    }
  };

  return (
    <GalleryContext.Provider
      value={{
        state,
        dispatch: reducer,
      }}
    >
      {children}
    </GalleryContext.Provider>
  );
};
