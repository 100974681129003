import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #fff;
  border: thin solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
`;

const Divider = styled.hr`
  display: block;
  max-width: 100%;
  height: 0;
  max-height: 0;
  border: solid;
  border-width: thin 0 0 0;
  border-color: rgba(0, 0, 0, 0.12);
`;

const Header = styled.div`
  padding: 8px 16px;
  min-height: 52px;
`;

const Title = styled.h2`
  margin: 0;
  line-height: 36px;
`;

const ContentWrapper = styled.div`
  padding: 16px;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 14%),
    0 0 0 0 rgb(0 0 0 / 12%);
  border-radius: ${(props) => (props.open ? "4px" : "4px 4px 0 0")};
`;

export { Wrapper, Divider, Header, Title, ContentWrapper };
