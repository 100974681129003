import styled from "styled-components";

const Wrapper = styled.div`
  background: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Logo = styled.img`
  max-width: 200px;
  max-height: 100px;
  margin-right: 20px;
`;

export { Wrapper, Logo };
