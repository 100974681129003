import styled, { keyframes } from "styled-components";

const Wrapper = styled.div`
  background-color: #fff;
  padding: 30px;
  margin: 48px auto;
  width: 100%;
  border-radius: 4px;
  max-width: 1072px;
  border: 1px solid rgb(223, 224, 235);
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  min-height: 400px;
`;
const ToolBar = styled.div`
  width: 100%;
  margin: 0 0 36px 0;
  display: flex;
`;

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  padding-right: 24px;
`;

const SelectWrapper = styled.div`
  margin-left: auto;
  margin-right: 24px;
  width: 200px;
`;

const InputWrapper = styled.div`
  margin-left: 6px;
  margin-right: auto;
  width: 400px;
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: ${(props) =>
    props.type === "pagination" ? "190px" : "calc(33% - 12px)"};
  animation: ${skeletonKeyframes} 1.2s ease-in-out infinite;
  border: 12px solid white;
  height: ${(props) => (props.type === "pagination" ? "55px" : "174px")};

  @media (max-width: 1199px) {
    width: ${(props) =>
      props.type === "pagination" ? "190px" : "calc(50% - 12px)"};
  }

  @media (max-width: 992px) {
    width: ${(props) =>
      props.type === "pagination" ? "190px" : "calc(100% - 12px)"};
  }
`;

const Text = styled.p`
  margin: 0 auto;
  text-align: center;
`;

const Input = styled.input`
  border-radius: 2px;
  border: 1px solid rgb(227, 233, 243);
  resize: none;
  width: 100%;
  height: 35px;
  padding-left: 12px;
  cursor: ${(props) => (props.as === "select" ? "pointer" : "text")};

  &::placeholder {
    padding-left: 12px;
    height: 35px;
    line-height: 35px;
  }
`;

export {
  Wrapper,
  SelectWrapper,
  ToolBar,
  Skeleton,
  PaginationWrapper,
  InputWrapper,
  Text,
  Input,
};
