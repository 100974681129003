import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import LogoLoader from "../LogoLoader/LogoLoader";

const RedirectWhen = ({ when, to, fallBack, ...props }) => {
  if (when === undefined) {
    return <LogoLoader />;
  } else if (when === true) {
    return <Redirect to={to} />;
  } else {
    const Component = fallBack;
    return <Component {...props} />;
  }
};

RedirectWhen.propTypes = {
  when: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(undefined)]),
  to: PropTypes.string.isRequired,
  fallBack: PropTypes.func.isRequired,
};

export default RedirectWhen;
