import styled from "styled-components";

const MainTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.3px;
  color: #252733;
`;

export { MainTitle };
