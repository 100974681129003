import styled from "styled-components";

const Img = styled.div.attrs((props) => {
  return {
    style: {
      backgroundImage: props.src ? `url("${props.src}")` : "none",
    },
  };
})`
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center;
`;

const IconWrapper = styled.div`
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  background-color: rgb(247, 248, 248);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { IconWrapper, Img };
