import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";

const DistrictForm = ({ data, updateData }) => {
  return (
    <FormBuilder
      fields={[
        {
          name: "internalName",
          label: "Internal name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. Only used for internal control.",
        },
        {
          name: "name",
          label: "District name",
          half: true,
          type: "text",
          required: true,
          information: "Required field. UI representation of the district.",
        },
        {
          name: "slug",
          label: "Slug",
          half: true,
          type: "slug",
          required: true,
          information: "Required field. A unique identifier of the district.",
        },
        {
          name: "regions",
          label: "District's Region",
          half: true,
          type: "relations",
          collection: "regions",
          required: true,
          information:
            "Required field. The regions to which this district belong",
        },
        {
          name: "counties",
          label: "Counties",
          half: true,
          type: "relations",
          collection: "counties",
          required: true,
          information:
            "Required field. The counties that belong to this district.",
        },
        {
          name: "image",
          label: "District image",
          half: true,
          type: "image",
          information: "A representative image of the district.",
        },
      ]}
      data={data}
      updateData={updateData}
    />
  );
};

export default DistrictForm;

export const gqlQuery = `
  query {
    internalName
    name
    slug
    image{
      url,
      _id
    }
    regions {
      internalName,
      _id
    }
    counties {
      internalName,
      _id
    }
    signature {
      name,
      date
    }
    _id
  }
`;
