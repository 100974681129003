import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  padding-bottom: 24px;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 32px;
  align-items: center;
`;

const TableWrapper = styled.div`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  position: relative;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  padding: 8px 16px;
  text-decoration: none;
  color: #ffffff;
  background-color: #3751ff;
  font-size: 13px;
  margin-left: auto;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  padding: 8px 16px;
  text-decoration: none;
  color: #ffffff;
  background-color: #f12b2c;
  font-size: 13px;
  margin-left: auto;
  font-weight: 600;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

const ActionsWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`;

export {
  Wrapper,
  TableWrapper,
  LoaderWrapper,
  Header,
  StyledLink,
  DeleteButton,
  ActionsWrapper,
};
