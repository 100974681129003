import styled from "styled-components";

const Wrapper = styled.div`
  padding-bottom: 24px;
  margin: 0 auto;
`;

const Title = styled.h1`
  position: relative;
  width: fit-content;
  max-width: 100%;
  padding-right: 18px;
  font-size: 2rem;
  line-height: normal;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 48px;
`;

export { Title, Wrapper };
