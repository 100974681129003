import React from "react";
import FormBuilder from "@components/FormBuilder/FormBuilder";
import TranslationsPanel from "@components/TranslationsPanel/TranslationsPanel";

const Homepage = ({ updateData, data, setState }) => {
  const { translations = [] } = data;

  return (
    <>
      <FormBuilder
        fields={[
          {
            type: "image",
            name: "bannerImage",
            label: "Hero banner image",
            half: false,
          },
          {
            type: "dnd-relations",
            collection: "tags",
            half: true,
            required: true,
            label: "Featured experiences",
            name: "experiences",
            information: "Row of feature experiences",
          },
          {
            type: "relation",
            collection: "tags",
            half: true,
            label: "Active campaign",
            name: "campaign",
            information: "If blank, campaign will be hidden from public",
          },
          {
            type: "dnd-relations",
            collection: "regions",
            half: true,
            label: "Slider of regions",
            name: "regions",
            information: "Row of featured regions",
          },
          {
            type: "dnd-relations",
            collection: "districts",
            half: true,
            label: "Slider of districts",
            name: "districts",
            information: "Row of featured districts",
          },
        ]}
        data={data}
        updateData={updateData}
      />
      <TranslationsPanel
        fields={[
          {
            type: "relation",
            name: "language",
            label: "Language",
            collection: "languages",
            half: true,
            information: "It's a required field",
            required: true,
            debounceTime: 300,
          },
          {
            type: "text",
            name: "metaTitle",
            label: "Meta tag title",
            information: "The title meta tag for the homepage",
            half: true,
          },
          {
            type: "text",
            name: "metaDescription",
            label: "Meta tag description",
            information: "The description meta tag for the homepage",
            half: true,
          },
          {
            type: "text",
            name: "bannerTitle",
            label: "Hero banner title",
            half: true,
            information: "The main title of the hero banner",
          },
          {
            type: "text",
            name: "bannerSubtitle",
            label: "Hero banner subtitle",
            half: true,
            information: "The subtitle below the main title of the hero banner",
          },
        ]}
        type={"Homepage"}
        updateData={updateData}
        setState={setState}
        translations={translations}
      />
    </>
  );
};

export default Homepage;

export const gqlQuery = `
  query {
    slug
    translations {
      _id
      language {
        internalName,
        _id
      }
      metaTitle,
      metaDescription,
      bannerTitle,
      bannerSubtitle,
    }
    signature {
      name,
      date
    }
    experiences {
      _id,
      internalName
    }
    campaign {
      _id,
      internalName
    }
    regions {
      _id,
      internalName
    }
    districts {
      _id,
      internalName
    }
    bannerImage {
      _id,
      url
    }
  }
`;
