import jwt from "jsonwebtoken";

const jwtStorageKey = process.env.REACT_APP_JWT_STORAGE_KEY || "_jwt";

const getToken = () => {
  const key = jwtStorageKey;
  const jwt = sessionStorage.getItem(key) || localStorage.getItem(key);
  return (jwt || "").replace(/['"]+/g, "");
};

const setToken = (token) => {
  const key = jwtStorageKey;
  sessionStorage.setItem(key, token);
};

const deleteToken = () => {
  const key = jwtStorageKey;
  sessionStorage.removeItem(key);
};

const isTokenInvalid = () => {
  try {
    const token = getToken();

    if (!token) return true;

    const payload = jwt.decode(token);

    if (!payload) return true;

    const isExpired = Date.now() >= payload.exp * 1000;

    return isExpired;
  } catch (e) {
    return true;
  }
};

const isSuperAdmin = () => {
  const token = getToken();
  if (!token) return false;
  const payload = jwt.decode(token);
  if (!payload) return false;
  const isSuperAdminRole = payload.role === "superadmin";
  return isSuperAdminRole;
};

export { isSuperAdmin, getToken, setToken, deleteToken, isTokenInvalid };
