import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
`;

const List = styled.div`
  position: relative;
  overflow: auto;
  height: 245px;
`;

const Item = styled.div`
  min-width: 290px;
  max-height: 210px;
  position: relative;
`;

const Button = styled.button`
  background-color: #fafafb;
  text-align: center;
  border: 1px solid rgb(174, 212, 251);
  position: sticky;
  top: 0;
  right: ${(props) => (props.adjustPosition ? "12px" : "0")};
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  border-radius: 100%;
  margin: ${(props) =>
    props.adjustPosition ? "auto 24px -2px auto" : "auto 12px 12px auto"};
  font-size: 1.875rem;
  letter-spacing: 0.25px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);
  -webkit-font-smoothing: antialiased;
  transition: box-shadow 0.08s linear,
    min-width 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  color: rgb(110, 193, 228);
  cursor: pointer;
  &:active,
  &:focus,
  &:visited {
    outline: none;
  }

  &:hover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.302),
      0 4px 8px 3px rgba(60, 64, 67, 0.149);
    background-color: rgb(174, 212, 251);
    color: rgb(0, 126, 255);
  }
`;

const DeleteButton = styled.button`
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(227, 233, 243);
  border-radius: 2px;
  cursor: pointer;
  font-size: 12px;
  color: rgb(158, 167, 184);
  position: absolute;
  top: 6px;
  right: 6px;

  &:hover {
    background-color: rgb(255, 167, 132);
    border: 1px solid rgb(255, 167, 132);
    color: rgb(246, 77, 10);
  }

  &:active,
  &:focus,
  &:visited {
    outline: none;
  }
`;

const NoEntries = styled.div`
  position: absolute;
  width: 98.5%;
  height: 96%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  font-size: 14px;
  margin-top: 12px;
  font-weight: 600;
  color: rgb(158, 167, 184);
`;

const Chip = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  color: #fff;
  background-color: rgb(42, 172, 226);
  margin: 4px;
  display: inline-flex;
  outline: 0;
  padding: 4px 16px;
  font-size: 0.7125rem;
  box-sizing: border-box;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  white-space: nowrap;
  border-radius: 16px;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.302),
    0 1px 3px 1px rgba(60, 64, 67, 0.149);
`;

export { Wrapper, List, Item, Button, DeleteButton, NoEntries, Text, Chip };
