import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const StyledLink = styled(Link)`
  border: 1px solid #dfe0eb;
  border-radius: 8px;
  padding: 36px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 220px;
  width: 100%;
  height: 180px;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.1s linear;

  margin: 12px 24px;

  &:hover {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%),
      0 2px 4px -1px rgb(0 0 0 / 20%);
    transform: translate(1px);
  }
`;

const Label = styled.span`
  font-weight: 600;
  color: #011f3b;
  margin-top: 12px;
  font-size: 13px;
  text-decoration: none;
`;

export { Wrapper, StyledLink, Label };
